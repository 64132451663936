<template>
  <v-btn icon @click="showDialog" id="infoTooltip">
    <vs-icon style="cursor: pointer" :color="color" size="15px" class="m-0 p-0" icon="info" />
  </v-btn>
  <!-- <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
      <v-icon color="primary" dark v-bind="attrs" v-on="on">Left</v-icon>
    </template>
    <span>Left tooltip</span>
  </v-tooltip>-->
  <!-- <span class="tooltip fade bs-tooltip-top show" v-b-tooltip.click :title="texto">
    <vs-icon icon="info" style="cursor: pointer" size="15px" class="m-0 p-0" />
  </span>-->
</template>
<script>
import components from "@/components/default/exports.js";
export default {
  props: {
    titulo: null,
    position: { default: "left" },
    texto: null,
    color: null,
  },
  data() {
    return {
      reRender: 0,
    };
  },
  components: {
    ...components,
  },
  mounted() {
    this.reRender += 1;
  },
  methods: {
    async showDialog() {
      this.$alert(
        `${this.texto}`,
        `${this.titulo ? this.titulo : "Descrição"}`,
        {
          showConfirmButton: false,
          dangerouslyUseHTMLString: true,
        }
      );
    },
  },
};
</script>
<style scoped lang="scss">
.el-popover * {
  color: red !important;
}
button#infoTooltip {
  background-color: transparent;
  border-style: none;
}
</style>
