<template>
  <div class="w-full">
    <div class="w-full px-2 py-1">
      <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
        <div class="w-full py-1">
          <vs-col
            :vs-w="tipo == 3 ? '7' : '12'"
            vs-type="flex"
            :vs-justify="tipo == 3 ? 'flex-end' : 'center'"
            vs-align="center"
          >
            <span v-if="tipo == 1">
              {{ valores[0] }}
              <b>{{ valores[1] }}</b>
            </span>
            <span v-else-if="tipo == 2">
              {{ valores[0] }}
              <b>{{ valores[1] }}</b>
            </span>
            <span v-else :class="tipo == 3 ? 'mr-2' : ''">
              <b>
                {{ valores[0] }}
                de {{ valores[1] }}
              </b>
            </span>
          </vs-col>
          <vs-col
            :vs-w="tipo == 3 ? '5' : '12'"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
          >
            <div
              aria-valuemin="0"
              aria-valuemax="100"
              class="w-full el-progress el-progress--line el-progress--text-inside"
            >
              <!-- :style="'border-color: ' + color + ' !important'" -->

              <div class="el-progress-bar">
                <div
                  :class="
                    tipo != 1
                      ? 'el-progress-bar__outer border widthBar'
                      : 'el-progress-bar__outer border widthBarBlack'
                  "
                >
                  <div
                    class="el-progress-bar__inner"
                    :style="
                      'width: ' +
                      (percent > 100 ? 100 : isNaN(percent) ? 0 : percent) +
                      '%; background: ' +
                      color
                    "
                  >
                    <div class="el-progress-bar__innerText">
                      <b :style="tipo == 1 ? 'color: #fff' : 'color: #000'">
                        <span style="font-size: 10px" v-if="tipo != 1">
                          {{
                            percent > 100
                              ? "+100"
                              : isNaN(percent)
                              ? "0.00"
                              : percent
                          }}%
                        </span>
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vs-col>
        </div>
      </vs-col>
    </div>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
import VueApexCharts from "vue-apexcharts"
export default {
  props: {
    height: { default: 10 },
    tipo: { default: 0 },
    valores: null,
    percent: { default: 0 },
    color: { default: "#00d6b9" },
    label: null,
    textColor: null,
  },
  data () {
    return { valor: 23 }
  },
  methods: {},
  components: {
    ...components,
    VueApexCharts,
  },
};
</script>
<style scoped>
.widthBar {
  height: 16px;
}
.widthBarBlack {
  height: 6px;
}
</style>
