<template>
  <div>
    <vs-row type="flex" vs-justify="space-around">
      <vs-col
        vs-w="4"
        class="px-2"
        v-for="(plano, index) in planos"
        :key="index"
      >
        <vx-card
          @click="showModalFatChart(plano)"
          style="cursor: pointer"
          :class="'plan-card overflow-hidden my-1'"
        >
          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <span style>{{ plano.tipo_faturamento }}</span>
          </vs-col>
          <vs-divider
            border-style="dotted"
            color
            class="py-0 mb-0 my-0"
          ></vs-divider>
          <div>
            <div>
              <vs-row class="mt-4">
                <vs-col vs-type vs-justify="center" vs-align="center" vs-w="12">
                  <h6 class="text-center p-0 m-0" style="color: black">
                    {{ plano.qtd ? plano.qtd : 0 }} {{ label }}
                  </h6>
                </vs-col>

                <vs-col vs-type vs-justify="center" vs-align="center" vs-w="12">
                  <h5 class="text-center p-0 m-0" style="color: #00bba2">
                    {{ $currency(plano.valor) }}
                  </h5>
                </vs-col>
              </vs-row>
            </div>
          </div>
        </vx-card>
      </vs-col>
    </vs-row>
    <component
      :open="true"
      :label="label"
      :plano="selectedPlano"
      :filtro="filtro"
      v-bind:is="modalFatChart"
      :key="reRenderModal"
    ></component>
  </div>
</template>

<script>
import faturamentoChartSideBar from "./../sideBars/faturamentoChartSideBar"
export default {
  props: {
    label: null,
    planos: null,
    filtro: null,
  },
  data () {
    return {
      modalFatChart: null,
      reRenderModal: 1,
      selectedPlano: null,
    }
  },
  methods: {
    async showModalFatChart (plano) {
      this.selectedPlano = plano
      this.reRenderModal = this.reRenderModal + 1
      this.modalFatChart = "faturamentoChartSideBar"
    },
  },
  components: {
    faturamentoChartSideBar,
  },
};
</script>
<style lang="scss" scoped>
.plan-card {
  transition-duration: 50ms;
  background-color: #fbfbfb;
  border-style: solid;
  border-radius: 7px;
  border-color: #000a12;
  border-width: 2px;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-5px);
    border-color: #00bba2;
    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
