<template>
  <div>
    <vside
      @hide="fechar()"
      :active="modalParcelasChart"
      :item="{}"
      size="huger"
      textSucces="Finalizar"
    >
      <div slot="body">
        <div
          id="container-parcelas-chart"
          class="mb-1 vs-con-loading__container"
        >
          <div
            class="mt-1"
            v-if="tableData.length == 0 || tableData.length == undefined"
          >
            <div class="pt-20 con-colors">
              <ul class="pt-20">
                <li class="danger-box">
                  <h2 class="p-5" style="color: white !important">
                    Nenhum Documento
                  </h2>
                </li>
              </ul>
            </div>
          </div>
          <div v-else class>
            <div>
              <div class>
                <vx-card card-background="dark">
                  <div class="p-2" style="color: white !important">
                    <span style="font-size: 20px">
                      <b style="text-transform: uppercase">{{ label }}</b>
                      - {{ parcela.parcela }}:&nbsp;
                    </span>

                    <span class="ml-2" style="font-size: 22px">
                      <b style="color: #00bba2">{{
                        $currency(
                          tableData.reduce((total, item) => {
                            return total + item.valor;
                          }, 0)
                        )
                      }}</b>
                      <b>&nbsp;/&nbsp;</b>
                      <b style="color: #ffca00">{{ tableData.length }}</b>
                    </span>
                  </div>
                </vx-card>
              </div>
            </div>
            <!-- <VuePerfectScrollbar class="scroll-table-data" :settings="settings"> -->
            <vs-table
              maxHeight="79vh"
              pagination
              max-items="9"
              :data="tableData"
              stripe
            >
              <template slot="thead">
                <vs-th sort-key="nota" style="width: 2%" id="center"
                  >Contrato</vs-th
                >
                <vs-th sort-key="nota" style="width: 2%" id="center"
                  >Contrato Status</vs-th
                >
                <vs-th sort-key="emissao" style="width: 8%" id="center"
                  >Cliente</vs-th
                >
                <vs-th sort-key="vencimento" style="width: 8%" id="center"
                  >Tipo Faturamento</vs-th
                >
                <vs-th sort-key="vencimento" style="width: 8%" id="center"
                  >Controle</vs-th
                >
                <vs-th sort-key="vencimento" style="width: 8%" id="center"
                  >Segmento</vs-th
                >
                <vs-th sort-key="vencimento" style="width: 8%" id="center"
                  >Proveniente</vs-th
                >
                <vs-th sort-key="valor" style="width: 8%" id="center"
                  >Parcela</vs-th
                >
                <vs-th style="width: 8%" id="center">Status Faturamento</vs-th>
                <vs-th sort-key="valor" style="width: 8%" id="center"
                  >Valor</vs-th
                >
                <vs-th sort-key="quitacao" style="width: 8%" id="center"
                  >Data Competência</vs-th
                >
                <vs-th style="width: 8%" id="center">Data Faturamento</vs-th>
                <vs-th style="width: 8%" id="center">Vendedor</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :key="row.contrato" v-for="row in data">
                  <vs-td class="text-center small-font">
                    <a
                      v-if="row.contrato > 0"
                      :href="'contrato?id=' + row.contrato"
                      style="font-size: 12px; color: darkblue; cursor: pointer"
                    >
                      <b>{{ row.contrato > 0 ? row.contrato : "..." }}</b>
                    </a></vs-td
                  >
                  <vs-td class="text-center small-font">
                    {{
                      row.renovacao > 0 && row.id_relacional > 0
                        ? "Renovação"
                        : row.id_status == 3
                        ? "Cancelado"
                        : "Novo"
                    }}</vs-td
                  >
                  <vs-td class="text-center small-font">{{
                    row.cliente
                  }}</vs-td>
                  <vs-td class="text-center small-font">
                    {{
                      row.tipo_faturamento
                        ? row.tipo_faturamento
                        : "Não Informado"
                    }}
                  </vs-td>
                  <vs-td class="text-center small-font">{{
                    row.controle
                  }}</vs-td>
                  <vs-td class="text-center small-font">{{
                    row.segmento
                  }}</vs-td>
                  <vs-td class="text-center small-font">{{
                    row.proveniente
                  }}</vs-td>
                  <vs-td class="text-center small-font">{{
                    row.parcela
                  }}</vs-td>
                  <vs-td class="text-center small-font">{{
                    row.status_faturamento
                  }}</vs-td>
                  <vs-td class="text-center small-font">{{
                    $currency(row.valor)
                  }}</vs-td>
                  <vs-td class="text-center small-font">
                    {{
                      row.data_competencia ? row.data_competencia : "Sem data"
                    }}
                  </vs-td>
                  <vs-td class="text-center small-font">
                    {{
                      row.data_faturamento ? row.data_faturamento : "Sem data"
                    }}
                  </vs-td>
                  <vs-td class="text-center small-font">{{
                    row.vendedor
                  }}</vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <!-- </VuePerfectScrollbar> -->
          </div>
        </div>
      </div>
    </vside>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
export default {
  props: {
    parcela: null,
    label: null,
    filtro: null
  },
  data () {
    return {
      tableData: {},
      modalParcelasChart: false,
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 1,
        height: 1000
      }
    }
  },
  methods: {
    async fechar () {
      this.modalParcelasChart = false
    },
    async getData () {
      await this.$vs.loading({
        container: "#container-parcelas-chart",
        type: "sound",
        scale: 0.6
      })
      try {
        this.tableData = await this.$http.post("getParcelaSideBar", {
          filtro: this.filtro,
          label: this.label,
          tipoParcela: this.parcela.parcela
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close(
          "#container-parcelas-chart > .con-vs-loading"
        )
      }
    }
  },
  async mounted () {
    this.modalParcelasChart = true
    await this.getData()
  },
  components: {
    ...components
  }
};
</script>
<style lang="scss">
.scroll-table-data {
  height: 90vh !important;
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
.small-font {
  font-size: 11px;
}
</style>
