<template>
  <div>
    <vside
      @hide="fechar()"
      :active="modalLeadSidebar"
      :item="{}"
      size="huger"
      textSucces="Finalizar"
    >
      <div slot="body">
        <div id="container-lead-sidebar" class="mb-1 vs-con-loading__container">
          <div
            class="mt-1"
            v-if="tableData.length == 0 || tableData.length == undefined"
          >
            <div class="pt-20 con-colors">
              <ul class="pt-20">
                <li class="danger-box">
                  <h2 class="p-5" style="color: white !important">
                    Nenhum Contato
                  </h2>
                </li>
              </ul>
            </div>
          </div>
          <div v-else>
            <div>
              <div>
                <vx-card card-background="dark">
                  <div class="p-2" style="color: white !important">
                    <span
                      style="font-size: 20px; text-transform: uppercase"
                      class="font-bold"
                    >
                      {{ item.label }}:
                      <span
                        class="ml-2"
                        style="font-size: 22px; color: #ffca00"
                      >
                        {{ tableData ? tableData.length : 0 }}
                      </span>
                      <span>&</span>
                      <span
                        class="ml-2"
                        style="font-size: 22px; color: #00bba2"
                      >
                        {{
                          tableData
                            ? $currency(
                                tableData.reduce(
                                  (acc, item) => acc + item.valor,
                                  0
                                )
                              )
                            : 0
                        }}
                      </span>
                    </span>
                  </div>
                </vx-card>
              </div>
            </div>
            <vs-table
              maxHeight="79vh"
              pagination
              max-items="15"
              :data="tableData"
              stripe
            >
              <template slot="thead">
                <vs-th sort-key="id_contrato" style="width: 10%" id="center">
                  N° Contrato
                </vs-th>
                <vs-th sort-key="id_cliente" style="width: 10%" id="center">
                  Cliente
                </vs-th>
                <vs-th style="width: 10%" id="center">Vendedor</vs-th>
                <vs-th style="width: 10%" id="center">Valor</vs-th>
                <vs-th style="width: 10%" id="center">Tipo Controle</vs-th>
                <vs-th style="width: 10%" id="center">Proveniente</vs-th>
                <vs-th style="width: 10%" id="center">Contato</vs-th>
                <vs-th style="width: 10%" id="center">Assunto</vs-th>
                <vs-th style="width: 10%" id="center">Data</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :key="row.contrato" v-for="row in data">
                  <vs-td class="text-center font-bold small-font">
                    <a
                      v-if="row.id_contrato > 0"
                      :href="'contrato?id=' + row.id_contrato"
                      style="font-size: 12px; color: darkblue; cursor: pointer"
                    >
                      <b>{{ row.id_contrato > 0 ? row.id_contrato : "..." }}</b>
                    </a>
                  </vs-td>
                  <vs-td class="text-center small-font">{{
                    row.cliente
                  }}</vs-td>
                  <vs-td class="text-center small-font">{{
                    row.vendedor
                  }}</vs-td>
                  <vs-td class="text-center small-font font-bold">{{
                    $currency(row.valor)
                  }}</vs-td>
                  <vs-td class="text-center small-font">{{
                    row.controle
                  }}</vs-td>
                  <vs-td class="text-center small-font">{{
                    row.proveniente
                  }}</vs-td>
                  <vs-td class="text-center small-font">{{
                    row.tipo_contato
                  }}</vs-td>
                  <vs-td class="text-center small-font">{{
                    row.assunto
                  }}</vs-td>
                  <vs-td class="text-center small-font">{{
                    row.data_criacao
                  }}</vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </div>
    </vside>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
export default {
  props: {
    item: null,
    tipo: null,
    tipoContato: null,
    filtro: null
  },
  data () {
    return {
      tableData: {},
      modalLeadSidebar: false,
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 1,
        height: 1000
      }
    }
  },
  methods: {
    async fechar () {
      this.modalLeadSidebar = false
    },
    async getData () {
      await this.$vs.loading({
        container: "#container-lead-sidebar",
        type: "sound",
        scale: 0.6
      })
      try {
        this.tableData = await this.$http.post("getSidebarPropostasFunil", {
          filtro: this.filtro,
          tipo: this.tipo,
          tipoContato: this.tipoContato
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close(
          "#container-lead-sidebar > .con-vs-loading"
        )
      }
    }
  },
  async mounted () {
    console.log(this.tipo)
    console.log(this.tipoContato)
    console.log(this.filtro)
    console.log(this.item)
    this.modalLeadSidebar = true
    await this.getData()
  },
  components: {
    ...components
  }
};
</script>
<style lang="scss">
.scroll-table-data {
  height: 90vh !important;
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
</style>
