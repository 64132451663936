<template>
  <div>
    <vue-apex-charts
      :key="reRender"
      type="donut"
      width="325"
      @dataPointSelection="dataPointSelectionHandler"
      :options="donutChart"
      :series="series"
    ></vue-apex-charts>
    <component
      :label="label"
      :dataDonut="selectedDataDonut"
      :filtro="filtro"
      v-bind:is="modalDonutChart"
      :key="reRenderModal"
    ></component>
  </div>
</template>
<script>
import components from '@/components/default/exports.js'
import VueApexCharts from 'vue-apexcharts'
import declinioChartSideBar from './../sideBars/declinioChartSideBar'
import segmentoChartSideBar from './../sideBars/segmentoChartSideBar'
export default {
  props: {
    filtro: null,
    propLabels: null,
    series: null,
    colors: null,
    label: null,
    indices: null,
    valores: null,
    tipo: null,
    textColor: null,
  },
  data () {
    return {
      selectedDataDonut: {},
      modalDonutChart: '',
      reRenderModal: 0,
      reRender: 0,
      donutChart: {
        colors: this.colors,
        states: {
          active: {
            allowMultipleDataPointsSelection: true,
          },
        },
        chart: {
          events: {
            dataPointSelection: function (event, chartContext, config) { },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            },
            title: {
              formatter: function (seriesName) {
                return seriesName.split(': ')[0]
              },
            },
          },
        },
        theme: {
          mode: 'light',
          palette: 'palette1',
          monochrome: {
            enabled: true,
            color: this.colors,
            shadeTo: 'light',
            shadeIntensity: 0.65,
          },
        },
        labels: this.propLabels,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'top',
              },
            },
          },
        ],
      },
    }
  },
  methods: {
    dataPointSelectionHandler (e, chartContext, config) {
      this.selectedDataDonut.label = this.propLabels[config.dataPointIndex]
      this.selectedDataDonut.indice = this.indices[config.dataPointIndex]
      if (this.tipo == 1) {
        this.selectedDataDonut.valor = this.valores.find(
          (x) =>
            x.segmento == this.selectedDataDonut.label.split(': ')[0].trim(),
        ).valor
        this.selectedDataDonut.qtd = this.series[config.dataPointIndex]
        this.modalDonutChart = 'segmentoChartSideBar'
      } else {
        this.selectedDataDonut.qtd = this.series[config.dataPointIndex]
        this.selectedDataDonut.valor = this.valores[config.dataPointIndex]
        this.modalDonutChart = 'declinioChartSideBar'
      }
      this.reRenderModal += 1
    },
  },
  components: {
    ...components,
    VueApexCharts,
    declinioChartSideBar,
    segmentoChartSideBar,
  },
}
</script>
<style scoped lang="scss"></style>
