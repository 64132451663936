<template>
  <v-card class="mx-auto" max-width="2000">
    <v-card-actions
      @click="show = !show"
      class="p-0"
      style="cursor: pointer; background-color: #ffca00"
    >
      <h4 class="px-3 mt-2">Contratos</h4>
      <span v-if="active" class="w-full">
        <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
          <vs-col
            vs-type="flex"
            vs-w="3"
            vs-justify="flex-start"
            vs-align="flex-start"
          ></vs-col>
          <vs-col
            vs-type="flex"
            vs-w="4.5"
            vs-justify="center"
            vs-align="center"
          >
            <metaChart
              :tipo="3"
              :valores="[
                contratosPlanos.metas.qtdTotal,
                (contratosPlanos.metas.qtd_meta / filtro.diasUteis) *
                  filtro.diasCorridos,
              ]"
              :percent="
                parseFloat(
                  (contratosPlanos.metas.qtdTotal * 100) /
                    ((contratosPlanos.metas.qtd_meta / filtro.diasUteis) *
                      filtro.diasCorridos)
                ).toFixed(2)
              "
            ></metaChart>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-w="4.5"
            vs-justify="center"
            vs-align="center"
          >
            <metaChart
              :tipo="3"
              :valores="[
                $currency(parseFloat(contratosPlanos.metas.valorTotal)),
                $currency(
                  parseFloat(
                    (contratosPlanos.metas.valor_meta / filtro.diasUteis) *
                      filtro.diasCorridos
                  )
                ),
              ]"
              :percent="
                parseFloat(
                  (contratosPlanos.metas.valorTotal * 100) /
                    ((contratosPlanos.metas.valor_meta / filtro.diasUteis) *
                      filtro.diasCorridos)
                ).toFixed(2)
              "
            ></metaChart>
          </vs-col>
        </vs-row>
      </span>
    </v-card-actions>
    <v-expand-transition>
      <div v-if="show">
        <v-card-text class="p-0 mt-2">
          <div>
            <div id="container-contrato" class="vs-con-loading__container">
              <div v-if="active">
                <vs-row
                  vs-type="flex"
                  vs-w="12"
                  vs-justify="center"
                  vs-align="center"
                >
                  <vs-col
                    vs-type="flex"
                    vs-w="4"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <div class="mx-2 w-full row-card">
                      <vs-col
                        vs-type="flex"
                        vs-w="12"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <div class="w-full">
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                              >&nbsp;</vs-col
                            >
                            <vs-col
                              vs-w="10"
                              class="pt-3"
                              vs-type="flex"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <h5 class="font-semibold">CONTRATOS ATIVOS</h5>
                            </vs-col>
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-end"
                              vs-align="flex-end"
                            >
                              <infoTooltip
                                color="dark"
                                :position="'left'"
                                titulo="CONTRATOS ATIVOS"
                                :texto="tooltipTextos.ativa"
                              ></infoTooltip>
                            </vs-col>
                          </vs-row>
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <pieChart
                              :propLabels="contratosAtivos.labels"
                              :series="contratosAtivos.series"
                              :indices="contratosAtivos.indices"
                              :filtro="filtro"
                              :colors="colors.chartAtivo"
                              percent="23"
                              label="contratos ativos"
                            ></pieChart>
                          </vs-row>
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <vs-col
                              vs-type="flex"
                              vs-w="6"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <metaChart
                                :valores="[
                                  contratosAtivos.meta.total_qtd,
                                  (contratosAtivos.meta.meta_qtd /
                                    filtro.diasUteis) *
                                    filtro.diasCorridos,
                                ]"
                                :percent="
                                  parseFloat(
                                    (contratosAtivos.meta.total_qtd * 100) /
                                      ((contratosAtivos.meta.meta_qtd /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos)
                                  ).toFixed(2)
                                "
                                label="Ativas"
                              ></metaChart>
                            </vs-col>
                            <vs-col
                              vs-type="flex"
                              vs-w="6"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <metaChart
                                :valores="[
                                  $currency(
                                    parseFloat(contratosAtivos.meta.total_valor)
                                  ),
                                  $currency(
                                    parseFloat(
                                      (contratosAtivos.meta.meta_valor /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos
                                    )
                                  ),
                                ]"
                                :percent="
                                  parseFloat(
                                    (contratosAtivos.meta.total_valor * 100) /
                                      ((contratosAtivos.meta.meta_valor /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos)
                                  ).toFixed(2)
                                "
                                label="Ativas"
                              ></metaChart>
                            </vs-col>
                          </vs-row>
                        </div>
                      </vs-col>
                    </div>
                  </vs-col>
                  <vs-col vs-type="flex" vs-w="4">
                    <div class="mx-2 w-full row-card">
                      <vs-col
                        vs-type="flex"
                        vs-w="12"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <div class="w-full">
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                              >&nbsp;</vs-col
                            >
                            <vs-col
                              vs-w="10"
                              class="pt-3"
                              vs-type="flex"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <h5 class="font-semibold">
                                CONTRATOS RECEPTIVOS
                              </h5>
                            </vs-col>
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-end"
                              vs-align="flex-end"
                            >
                              <infoTooltip
                                titulo="CONTRATOS RECEPTIVOS"
                                color="dark"
                                :position="'left'"
                                :texto="tooltipTextos.receptiva"
                              ></infoTooltip>
                            </vs-col>
                          </vs-row>
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <pieChart
                              :propLabels="contratosReceptivos.labels"
                              :series="contratosReceptivos.series"
                              :colors="colors.chartReceptivo"
                              :indices="contratosReceptivos.indices"
                              :filtro="filtro"
                              :percent="23"
                              label="contratos receptivos"
                            ></pieChart>
                          </vs-row>
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <vs-col
                              vs-type="flex"
                              vs-w="6"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <metaChart
                                :valores="[
                                  contratosReceptivos.meta.total_qtd,
                                  (contratosReceptivos.meta.meta_qtd /
                                    filtro.diasUteis) *
                                    filtro.diasCorridos,
                                ]"
                                :percent="
                                  parseFloat(
                                    (contratosReceptivos.meta.total_qtd * 100) /
                                      ((contratosReceptivos.meta.meta_qtd /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos)
                                  ).toFixed(2)
                                "
                                label="receptivas"
                              ></metaChart>
                            </vs-col>
                            <vs-col
                              vs-type="flex"
                              vs-w="6"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <metaChart
                                :valores="[
                                  $currency(
                                    parseFloat(
                                      contratosReceptivos.meta.total_valor
                                    )
                                  ),
                                  $currency(
                                    parseFloat(
                                      (contratosReceptivos.meta.meta_valor /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos
                                    )
                                  ),
                                ]"
                                :percent="
                                  parseFloat(
                                    (contratosReceptivos.meta.total_valor *
                                      100) /
                                      ((contratosReceptivos.meta.meta_valor /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos)
                                  ).toFixed(2)
                                "
                                label="receptivas"
                              ></metaChart>
                            </vs-col>
                          </vs-row>
                        </div>
                      </vs-col>
                    </div>
                  </vs-col>
                  <vs-col vs-type="flex" vs-w="4">
                    <div class="mx-2 w-full row-card">
                      <vs-col
                        vs-type="flex"
                        vs-w="12"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <div class="w-full py-1">
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                              >&nbsp;</vs-col
                            >
                            <vs-col
                              vs-w="10"
                              class="pt-3"
                              vs-type="flex"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <h5 class="font-semibold">SEGMENTOS</h5>
                            </vs-col>
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-end"
                              vs-align="flex-end"
                            >
                              <infoTooltip
                                color="dark"
                                titulo="SEGMENTOS"
                                :position="'left'"
                                :texto="tooltipTextos.segmentos"
                              ></infoTooltip>
                            </vs-col>
                          </vs-row>
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <donutChart
                              :propLabels="contratosSegmentos.labels"
                              :series="contratosSegmentos.series"
                              :colors="colors.chartSegmentos"
                              :filtro="filtro"
                              :indices="contratosSegmentos.indices"
                              :tipo="1"
                              :valores="contratosSegmentos.valores"
                              percent="23"
                              label="contrato segmento"
                            ></donutChart>
                            <vs-col
                              v-for="segmento in contratosSegmentos.valores"
                              :key="segmento.segmento"
                              vs-w="6"
                              vs-type="flex"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <p>
                                {{ segmento.segmento }}:
                                <b>{{ segmento.qtd }}</b>
                              </p>
                            </vs-col>
                          </vs-row>
                        </div>
                      </vs-col>
                    </div>
                  </vs-col>
                </vs-row>
                <vs-row
                  class="my-3"
                  vs-type="flex"
                  vs-w="12"
                  vs-justify="flex-start"
                  vs-align="center"
                >
                  <vs-col vs-type="flex" vs-w="6">
                    <div class="mx-3 w-full row-card">
                      <vs-col
                        vs-type="flex"
                        vs-w="12"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <div class="w-full py-1">
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                              >&nbsp;</vs-col
                            >
                            <vs-col
                              vs-w="10"
                              class="pt-3"
                              vs-type="flex"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <h5 class="font-semibold">PLANOS</h5>
                            </vs-col>
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-end"
                              vs-align="flex-end"
                            >
                              <infoTooltip
                                color="dark"
                                titulo="PLANOS"
                                :position="'left'"
                                :texto="tooltipTextos.planos"
                              ></infoTooltip>
                            </vs-col>
                          </vs-row>
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <faturamentoChart
                              :planos="contratosPlanos.data"
                              :filtro="filtro"
                              :label="' contratos'"
                            ></faturamentoChart>
                            <vs-row
                              vs-type="flex"
                              vs-w="12"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <vs-col
                                vs-type="flex"
                                vs-w="6"
                                vs-justify="center"
                                vs-align="center"
                              >
                                <metaChart
                                  :valores="[
                                    contratosPlanos.metas.qtdTotal,
                                    (contratosPlanos.metas.qtd_meta /
                                      filtro.diasUteis) *
                                      filtro.diasCorridos,
                                  ]"
                                  :percent="
                                    parseFloat(
                                      (contratosPlanos.metas.qtdTotal * 100) /
                                        ((contratosPlanos.metas.qtd_meta /
                                          filtro.diasUteis) *
                                          filtro.diasCorridos)
                                    ).toFixed(2)
                                  "
                                  height="5"
                                  label="Total Propostas"
                                ></metaChart>
                              </vs-col>
                              <vs-col
                                vs-type="flex"
                                vs-w="6"
                                vs-justify="center"
                                vs-align="center"
                              >
                                <metaChart
                                  :valores="[
                                    $currency(
                                      parseFloat(
                                        contratosPlanos.metas.valorTotal
                                      )
                                    ),
                                    $currency(
                                      parseFloat(
                                        (contratosPlanos.metas.valor_meta /
                                          filtro.diasUteis) *
                                          filtro.diasCorridos
                                      )
                                    ),
                                  ]"
                                  :percent="
                                    parseFloat(
                                      (contratosPlanos.metas.valorTotal * 100) /
                                        ((contratosPlanos.metas.valor_meta /
                                          filtro.diasUteis) *
                                          filtro.diasCorridos)
                                    ).toFixed(2)
                                  "
                                  height="5"
                                  label="Valor"
                                ></metaChart>
                              </vs-col>
                            </vs-row>
                          </vs-row>
                        </div>
                      </vs-col>
                    </div>
                  </vs-col>
                  <vs-col vs-type="flex" vs-w="6">
                    <div class="mx-3 w-full row-card">
                      <vs-col
                        vs-type="flex"
                        vs-w="12"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <div class="w-full py-1">
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                              >&nbsp;</vs-col
                            >
                            <vs-col
                              vs-w="10"
                              class="pt-3"
                              vs-type="flex"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <h5 class="font-semibold">PARCELAS</h5>
                            </vs-col>
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-end"
                              vs-align="flex-end"
                            >
                              <v-icon color="grey lighten-1">mdi-cart</v-icon>
                            </vs-col>
                          </vs-row>
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <parcelasChart
                              :filtro="filtro"
                              :label="' contratos'"
                              :parcelas="contratosParcelas"
                            ></parcelasChart>
                          </vs-row>
                        </div>
                      </vs-col>
                    </div>
                  </vs-col>
                </vs-row>
                <vs-row
                  class="my-3"
                  vs-type="flex"
                  vs-w="12"
                  vs-justify="flex-start"
                  vs-align="center"
                >
                  <vs-col vs-type="flex" vs-w="6">
                    <div class="mx-3 w-full row-card">
                      <vs-col
                        vs-type="flex"
                        vs-w="12"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <div class="w-full py-1">
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                              >&nbsp;</vs-col
                            >
                            <vs-col
                              vs-w="10"
                              class="pt-3"
                              vs-type="flex"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <h5 class="font-semibold">CONTRATOS VENCENDO</h5>
                            </vs-col>
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-end"
                              vs-align="flex-end"
                            >
                              <infoTooltip
                                color="dark"
                                titulo="CONTRATOS VENCENDO"
                                :position="'left'"
                                :texto="tooltipTextos.vencendo"
                              ></infoTooltip>
                            </vs-col>
                          </vs-row>
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <faturamentoChart
                              :planos="contratosVencendo"
                              :label="' contratos venc'"
                              :filtro="filtro"
                            ></faturamentoChart>
                          </vs-row>
                        </div>
                      </vs-col>
                    </div>
                  </vs-col>

                  <vs-col vs-type="flex" vs-w="6">
                    <div class="mx-3 w-full row-card">
                      <vs-col
                        vs-type="flex"
                        vs-w="12"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <div class="w-full py-1">
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                              >&nbsp;</vs-col
                            >
                            <vs-col
                              vs-w="10"
                              class="pt-3"
                              vs-type="flex"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <h5 class="font-semibold">DECLINIO</h5>
                            </vs-col>
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-end"
                              vs-align="flex-end"
                            >
                              <infoTooltip
                                color="dark"
                                :position="'left'"
                                titulo="DECLINIO"
                                :texto="tooltipTextos.declino"
                              ></infoTooltip>
                            </vs-col>
                          </vs-row>
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <donutChart
                              :propLabels="contratosDeclinio.labels"
                              :series="contratosDeclinio.series"
                              :colors="colors.chartDeclinio"
                              :valores="contratosDeclinio.valores"
                              :filtro="filtro"
                              :indices="contratosDeclinio.indices"
                              :tipo="2"
                              percent="23"
                              label="contrato declinio"
                            ></donutChart>
                          </vs-row>
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <vs-col
                              vs-type="flex"
                              vs-w="6"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <metaChart
                                tipo="1"
                                :valores="[
                                  'Total Declinios: ',
                                  contratosDeclinio.total.qtd,
                                ]"
                                :percent="100"
                                color="black"
                                height="5"
                                label="Ativas"
                              ></metaChart>
                            </vs-col>
                            <vs-col
                              vs-type="flex"
                              vs-w="6"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <metaChart
                                tipo="1"
                                height="5"
                                :valores="[
                                  'Valor Perdido: ',
                                  $currency(
                                    parseFloat(contratosDeclinio.total.valor)
                                  ),
                                ]"
                                :percent="100"
                                color="black"
                                label="Ativas"
                              ></metaChart>
                            </vs-col>
                          </vs-row>
                        </div>
                      </vs-col>
                    </div>
                  </vs-col>
                </vs-row>
              </div>
              <div v-else>
                <vs-row
                  vs-type="flex"
                  vs-w="12"
                  vs-justify="center"
                  vs-align="center"
                >
                  <vs-col
                    vs-type="flex"
                    vs-w="4"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <p
                      class="mt-10 pt-10"
                      style="font-size: 30px; height: 600px"
                    >
                      Carregando... {{ loading }}%
                    </p>
                  </vs-col>
                </vs-row>
              </div>
            </div>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>
<script>
import componentsVendas from "./components/charts/exports.js";
import components from "@/components/default/exports.js";
import VueApexCharts from "vue-apexcharts";
export default {
  props: {
    show: Boolean,
    filtro: Object,
  },
  data() {
    return {
      active: false,
      loading: 0,
      contratosAtivos: [],
      contratosReceptivos: [],
      contratosSegmentos: [],
      contratosPlanos: [],
      contratosDeclinio: [],
      contratosParcelas: [],
      contratosVencendo: [],
      tooltipTextos: {
        ativa: `<b>PieChart</b> representando as <b>propostas</b>, onde na sua prospecção foi
        selecionado o tipo de atendimento ligação ativa/abordagem ativa.
        Separado por propostas novas e propostas de renovação // XXX`,
        receptiva: `PieChart representando as propostas, onde na sua prospecção foi
        selecionado o tipo de atendimento ligação receptiva/abordagem receptiva.
        Separado por propostas novas e propostas de renovação // XXX`,
        planos: `Planos`,
        segmentos: `Segmentos`,
        declinio: `Segmentos`,
        parcelas: `Segmentos`,
        vencendo: `Vencendo`,
      },
      colors: {
        chartAtivo: "#4caf50",
        chartReceptivo: "#ff6d00",
        chartSegmentos: "#0277bd",
        chartDeclinio: "#b71c1c",
      },
      seriesPieChart: [4, 97, 45, 12],
      seriesDonutChart: [4, 97, 45, 12],
      labelsPieChart: [],
      labelsDonutChart: [],
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.8,
      },
    };
  },
  methods: {
    async prepareDashboard() {
      await this.$vs.loading({
        container: "#container-contrato",
        type: "sound",
        scale: 0.6,
      });
      await this.getContratosAtivos();
      await this.getContratosReceptivos();
      await this.getContratosSegmentos();
      await this.getContratosPlanos();
      await this.getContratosDeclinio();
      await this.getContratosParcelas();
      await this.getContratosVencendo();
      this.active = true;
      await this.$vs.loading.close("#container-contrato > .con-vs-loading");
    },
    async getContratosAtivos() {
      try {
        this.loading += 7;
        this.contratosAtivos = await this.$http.post(
          "getContratosAtivos",
          this.filtro
        );
        this.loading += 7;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getContratosReceptivos() {
      try {
        this.loading += 7;
        this.contratosReceptivos = await this.$http.post(
          "getContratosReceptivos",
          this.filtro
        );
        this.loading += 7;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getContratosSegmentos() {
      try {
        this.loading += 7;
        this.contratosSegmentos = await this.$http.post(
          "getContratosSegmentos",
          this.filtro
        );
        this.loading += 7;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getContratosPlanos() {
      try {
        this.loading += 7;
        this.contratosPlanos = await this.$http.post(
          "getContratosPlanos",
          this.filtro
        );
        this.loading += 8;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getContratosDeclinio() {
      try {
        this.loading += 7;
        this.contratosDeclinio = await this.$http.post(
          "getContratosDeclinio",
          this.filtro
        );
        this.loading += 7;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getContratosParcelas() {
      try {
        this.loading += 7;
        this.contratosParcelas = await this.$http.post(
          "getContratosParcelas",
          this.filtro
        );
        this.loading += 7;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getContratosVencendo() {
      try {
        this.loading += 7;
        this.contratosVencendo = await this.$http.post(
          "getContratosVencendo",
          this.filtro
        );
        this.loading += 8;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
  },
  async mounted() {
    await this.prepareDashboard();
  },
  components: {
    ...components,
    ...componentsVendas,
    VueApexCharts,
  },
};
</script>
<style scoped lang="scss">
.scroll-relatorio-vendas {
  max-height: 78vh !important;
}
.row-border {
  border-radius: 0px;
  border-bottom-style: solid;
  border-width: 1px;
}

.row-card {
  transition-duration: 250ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #f0f0f0e0;
    border-radius: 10px;
    color: black;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.prospeccaoVendas {
  max-height: 60vh !important;
}
</style>
