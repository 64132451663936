
import circleChart from "./circleChart.vue";
import pieChart from "./pieChart.vue";
import donutChart from "./donutChart.vue";
import infoTooltip from "./infoTooltip.vue";
import radialChart from "./radialChart.vue";
import faturamentoChart from "./faturamentoChart.vue";
import parcelasChart from "./parcelasChart.vue";
import barChart from "./barChart.vue";
import metaChart from "./metaChart.vue";
import VuePerfectScrollbar from 'vue-perfect-scrollbar';


export default {
  metaChart,
  pieChart,
  faturamentoChart,
  donutChart,
  parcelasChart,
  radialChart,
  circleChart,
  barChart,
  infoTooltip,
  VuePerfectScrollbar
}
