<template>
  <vue-apex-charts
    type="radialBar"
    height="350"
    width="650"
    :key="reRender"
    :options="commitRadialBar"
    :series="series"
  ></vue-apex-charts>
</template>
<script>
import components from "@/components/default/exports.js";
import VueApexCharts from "vue-apexcharts";
export default {
  props: {
    categorias: null,
    series: null,
  },
  data() {
    return {
      reRender: 0,
      chartOptions: {
        chart: {
          height: 390,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 360,
            hollow: {
              margin: 0,
              size: "40%",
              background: "transparent",
              image: undefined,
            },
            dataLabels: {
              name: {
                show: true,
              },
              value: {
                show: false,
              },
              total: {
                show: true,
                label: "Total",
                formatter: function (w) {
                  // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                  return 249;
                },
              },
            },
          },
        },
        colors: ["#1ab7ea", "#0084ff", "#39539E", "#0077B5"],
        labels: [],
        legend: {
          show: true,
          floating: false,
          fontSize: "14px",
          position: "bottom",
          offsetX: 50,
          offsetY: 0,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0,
          },
          formatter: function (seriesName, opts) {
            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
          },
          itemMargin: {
            vertical: 3,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };
  },
  computed: {
    commitRadialBar() {
      let localOptions = Object.assign({}, this.chartOptions);
      localOptions.labels = this.categorias;
      this.reRender += 1;

      return localOptions;
    },
  },
  components: {
    ...components,
    VueApexCharts,
  },
};
</script>
<style scoped lang="scss">
</style>
