<template class="home">
  <div id="home">
    <vs-row>
      <div class="px-1 w-full">
        <filtroRelatorio
          @toggleCollapse="toggleCollapse()"
          @atualizarFiltro="atualizarFiltro"
        ></filtroRelatorio>
      </div>
    </vs-row>
    <vs-divider color="primary" class="m-0 mb-5"></vs-divider>
    <vs-col vs-w="12">
      <vs-row v-if="active">
        <vs-col vs-w="3">
          <div class="px-1 w-full">
            <taxasComercialVert
              :show="showLast"
              :key="reRender"
              :filtro="filtro"
            ></taxasComercialVert>
          </div>
        </vs-col>
        <vs-col vs-w="4.5">
          <div class="px-1 w-full">
            <prospeccao
              :show="showLast"
              :key="reRender"
              :filtro="filtro"
            ></prospeccao>
          </div>
        </vs-col>
        <vs-col vs-w="4.5">
          <div class="px-1 w-full">
            <cliente
              :show="showLast"
              :key="reRender"
              :filtro="filtro"
            ></cliente>
          </div>
        </vs-col>
        <vs-row>
          <div class="pt-5 px-1 w-full">
            <proposta
              :show="showFirst"
              :key="reRender"
              :filtro="filtro"
            ></proposta>
          </div>
        </vs-row>
        <vs-row>
          <div class="pt-5 px-1 w-full">
            <contrato
              :show="showFirst"
              :key="reRender"
              :filtro="filtro"
            ></contrato>
          </div>
        </vs-row>
        <vs-row>
          <div class="pt-5 px-1 w-full">
            <faturamento
              :show="showFirst"
              :key="reRender"
              :filtro="filtro"
            ></faturamento>
          </div>
        </vs-row>
        <vs-row>
          <vs-col vs-type="flex" vs-align="center" vs-justify="center">
            <div class="pt-5 px-1 w-full pb-10">
              <funil :filtro="filtro" :show="showLast" :key="reRender" />
            </div>
          </vs-col>
        </vs-row>
      </vs-row>
      <vs-row
        vs-w="12"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        v-else
      >
        <div class="relatorio">
          <vs-col
            class="mt-20 pt-20"
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <vs-icon size="100px" icon="sentiment_dissatisfied"></vs-icon>
          </vs-col>
          <vs-col
            class="mt-2"
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <span class="font-semibold">Filtre um Período</span>
          </vs-col>
        </div>
      </vs-row>
    </vs-col>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
import proposta from "./proposta"
import contrato from "./contrato"
import prospeccao from "./prospeccao"
import cliente from "./cliente"
import taxasComercialVert from "./taxasComercialVert"
import filtroRelatorio from "./filtroRelatorio"
import faturamento from "./faturamento"

import funil from "./funil/funil"
export default {
  data () {
    return {
      reRender: 0,
      showLast: true,
      showFirst: true,
      active: false,
      filtro: {
        vendedores: [],
        tipoContato: "3"
      },
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8
      }
    }
  },
  methods: {
    async atualizarFiltro (filtro) {
      if (filtro.dataDe && filtro.dataAte) {
        this.active = true
      } else {
        this.active = false
      }
      this.showLast = true
      this.showFirst = true
      this.filtro = filtro
      this.reRender += 1
    },
    async toggleCollapse () {
      this.showFirst = !this.showLast
      this.showLast = !this.showLast
    }
  },
  components: {
    ...components,
    proposta,
    prospeccao,
    taxasComercialVert,
    filtroRelatorio,
    contrato,
    cliente,
    faturamento,
    funil
  }
};
</script>
<style>
body {
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
}
.relatorio {
  max-height: 80vh !important;
}
</style>
