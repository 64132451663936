<template>
  <v-card class="mx-auto" max-width="2000">
    <v-card-actions
      @click="show = !show"
      class="p-0"
      style="cursor: pointer; background-color: #ffca00"
    >
      <h4 class="px-3 mt-2">Propostas</h4>
      <span v-if="active" class="w-full">
        <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
          <vs-col
            vs-type="flex"
            vs-w="3"
            vs-justify="flex-start"
            vs-align="flex-start"
          ></vs-col>
          <vs-col
            vs-type="flex"
            vs-w="4.5"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <metaChart
              :tipo="3"
              :valores="[
                propostasPlanos.metas.qtdTotal,
                (propostasPlanos.metas.qtd_meta / filtro.diasUteis) *
                  filtro.diasCorridos
              ]"
              :percent="
                parseFloat(
                  (propostasPlanos.metas.qtdTotal * 100) /
                    ((propostasPlanos.metas.qtd_meta / filtro.diasUteis) *
                      filtro.diasCorridos)
                ).toFixed(2)
              "
            ></metaChart>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-w="4.5"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <metaChart
              :tipo="3"
              :valores="[
                $currency(parseFloat(propostasPlanos.metas.valorTotal)),
                $currency(
                  parseFloat(
                    (propostasPlanos.metas.valor_meta / filtro.diasUteis) *
                      filtro.diasCorridos
                  )
                )
              ]"
              :percent="
                parseFloat(
                  (propostasPlanos.metas.valorTotal * 100) /
                    ((propostasPlanos.metas.valor_meta / filtro.diasUteis) *
                      filtro.diasCorridos)
                ).toFixed(2)
              "
            ></metaChart>
          </vs-col>
        </vs-row>
      </span>
    </v-card-actions>
    <v-expand-transition>
      <div v-if="show">
        <v-card-text class="p-0 mt-2">
          <div id="container-propostas" class="vs-con-loading__container">
            <div v-if="active">
              <vs-row
                vs-type="flex"
                vs-w="12"
                vs-justify="center"
                vs-align="center"
              >
                <vs-col
                  vs-type="flex"
                  vs-w="4"
                  vs-justify="center"
                  vs-align="center"
                >
                  <div class="mx-2 w-full row-card">
                    <vs-col
                      vs-type="flex"
                      vs-w="12"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <div class="w-full">
                        <vs-row
                          vs-type="flex"
                          vs-w="12"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-col
                            vs-w="1"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            >&nbsp;</vs-col
                          >
                          <vs-col
                            vs-w="10"
                            class="pt-3"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <h5 class="font-semibold">PROPOSTAS ATIVAS</h5>
                          </vs-col>
                          <vs-col
                            vs-w="1"
                            vs-type="flex"
                            vs-justify="flex-end"
                            vs-align="flex-end"
                          >
                            <infoTooltip
                              color="dark"
                              titulo="PROPOSTAS ATIVAS"
                              :position="'left'"
                              :texto="tooltipTextos.ativa"
                            ></infoTooltip>
                          </vs-col>
                        </vs-row>
                        <pieChart
                          :propLabels="propostasAtivas.labels"
                          :series="propostasAtivas.series"
                          :colors="colors.chartAtivo"
                          :indices="propostasAtivas.indices"
                          :filtro="filtro"
                          :percent="23"
                          label="propostas ativas"
                        ></pieChart>
                        <vs-col
                          vs-type="flex"
                          vs-w="6"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <metaChart
                            :valores="[
                              propostasAtivas.meta.total_qtd,
                              (propostasAtivas.meta.meta_qtd /
                                filtro.diasUteis) *
                                filtro.diasCorridos
                            ]"
                            :percent="
                              parseFloat(
                                (propostasAtivas.meta.total_qtd * 100) /
                                  ((propostasAtivas.meta.meta_qtd /
                                    filtro.diasUteis) *
                                    filtro.diasCorridos)
                              ).toFixed(2)
                            "
                            label="Ativas"
                          ></metaChart>
                        </vs-col>
                        <vs-col
                          vs-type="flex"
                          vs-w="6"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <metaChart
                            :valores="[
                              $currency(
                                parseFloat(propostasAtivas.meta.total_valor)
                              ),
                              $currency(
                                parseFloat(
                                  (propostasAtivas.meta.meta_valor /
                                    filtro.diasUteis) *
                                    filtro.diasCorridos
                                )
                              )
                            ]"
                            :percent="
                              parseFloat(
                                (propostasAtivas.meta.total_valor * 100) /
                                  ((propostasAtivas.meta.meta_valor /
                                    filtro.diasUteis) *
                                    filtro.diasCorridos)
                              ).toFixed(2)
                            "
                            label="Ativas"
                          ></metaChart>
                        </vs-col>
                      </div>
                    </vs-col>
                  </div>
                </vs-col>
                <vs-col vs-type="flex" vs-w="4">
                  <div class="mx-2 w-full row-card">
                    <vs-col
                      vs-type="flex"
                      vs-w="12"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <div class="w-full">
                        <vs-row
                          vs-type="flex"
                          vs-w="12"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-col
                            vs-w="1"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            >&nbsp;</vs-col
                          >
                          <vs-col
                            vs-w="10"
                            class="pt-3"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <h5 class="font-semibold">PROPOSTAS RECEPTIVAS</h5>
                          </vs-col>
                          <vs-col
                            vs-w="1"
                            vs-type="flex"
                            vs-justify="flex-end"
                            vs-align="flex-end"
                          >
                            <infoTooltip
                              color="dark"
                              titulo="PROPOSTAS RECEPTIVAS"
                              :position="'left'"
                              :texto="tooltipTextos.ativa"
                            ></infoTooltip>
                          </vs-col>
                        </vs-row>
                        <pieChart
                          :propLabels="propostasReceptivas.labels"
                          :series="propostasReceptivas.series"
                          :colors="colors.chartReceptivo"
                          :filtro="filtro"
                          :indices="propostasReceptivas.indices"
                          :percent="23"
                          label="propostas receptivas"
                        ></pieChart>
                        <vs-col
                          vs-type="flex"
                          vs-w="6"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <metaChart
                            :valores="[
                              propostasReceptivas.meta.total_qtd,
                              (propostasReceptivas.meta.meta_qtd /
                                filtro.diasUteis) *
                                filtro.diasCorridos
                            ]"
                            :percent="
                              parseFloat(
                                (propostasReceptivas.meta.total_qtd * 100) /
                                  ((propostasReceptivas.meta.meta_qtd /
                                    filtro.diasUteis) *
                                    filtro.diasCorridos)
                              ).toFixed(2)
                            "
                            label="Ativas"
                          ></metaChart>
                        </vs-col>
                        <vs-col
                          vs-type="flex"
                          vs-w="6"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <metaChart
                            :valores="[
                              $currency(
                                parseFloat(propostasReceptivas.meta.total_valor)
                              ),
                              $currency(
                                parseFloat(
                                  (propostasReceptivas.meta.meta_valor /
                                    filtro.diasUteis) *
                                    filtro.diasCorridos
                                )
                              )
                            ]"
                            :percent="
                              parseFloat(
                                (propostasReceptivas.meta.total_valor * 100) /
                                  ((propostasReceptivas.meta.meta_valor /
                                    filtro.diasUteis) *
                                    filtro.diasCorridos)
                              ).toFixed(2)
                            "
                            label="Ativas"
                          ></metaChart>
                        </vs-col>
                      </div>
                    </vs-col>
                  </div>
                </vs-col>
                <vs-col vs-type="flex" vs-w="4">
                  <div class="mx-2 w-full row-card">
                    <vs-col
                      vs-type="flex"
                      vs-w="12"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <div class="w-full py-1">
                        <vs-row
                          vs-type="flex"
                          vs-w="12"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-col
                            vs-w="1"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            >&nbsp;</vs-col
                          >
                          <vs-col
                            vs-w="10"
                            class="pt-3"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <h5 class="font-semibold">SEGMENTOS</h5>
                          </vs-col>
                          <vs-col
                            vs-w="1"
                            vs-type="flex"
                            vs-justify="flex-end"
                            vs-align="flex-end"
                          >
                            <infoTooltip
                              color="dark"
                              titulo="SEGMENTOS"
                              :position="'left'"
                              :texto="tooltipTextos.segmentos"
                            ></infoTooltip>
                          </vs-col>
                        </vs-row>
                        <vs-row
                          vs-type="flex"
                          vs-w="12"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-col
                            vs-w="12"
                            vs-type="flex"
                            vs-justify="flex-end"
                            vs-align="flex-end"
                          >
                            <donutChart
                              :propLabels="propostasSegmentos.labels"
                              :series="propostasSegmentos.series"
                              :colors="colors.chartSegmentos"
                              :valores="propostasSegmentos.valores"
                              :indices="propostasSegmentos.indices"
                              :filtro="filtro"
                              :tipo="1"
                              :percent="23"
                              label="proposta segmento"
                            ></donutChart>
                          </vs-col>
                          <vs-col
                            v-for="segmento in propostasSegmentos.valores"
                            :key="segmento.segmento"
                            vs-w="6"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <p>
                              {{ segmento.segmento }}:
                              <b>{{ segmento.qtd }}</b>
                            </p>
                          </vs-col>
                        </vs-row>
                      </div>
                    </vs-col>
                  </div>
                </vs-col>
              </vs-row>
              <vs-row
                class="my-3"
                vs-type="flex"
                vs-w="12"
                vs-justify="flex-start"
                vs-align="center"
              >
                <vs-col vs-type="flex" vs-w="6">
                  <div class="mx-2 w-full row-card">
                    <vs-col
                      vs-type="flex"
                      vs-w="12"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <div class="w-full py-1">
                        <vs-row
                          vs-type="flex"
                          vs-w="12"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-col
                            vs-w="1"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            >&nbsp;</vs-col
                          >
                          <vs-col
                            vs-w="10"
                            class="pt-3"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <h5 class="font-semibold">PLANOS</h5>
                          </vs-col>
                          <vs-col
                            vs-w="1"
                            vs-type="flex"
                            vs-justify="flex-end"
                            vs-align="flex-end"
                          >
                            <infoTooltip
                              color="dark"
                              :position="'left'"
                              titulo="PLANOS"
                              :texto="tooltipTextos.planos"
                            ></infoTooltip>
                          </vs-col>
                        </vs-row>
                        <vs-row
                          vs-type="flex"
                          vs-w="12"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <faturamentoChart
                            :label="' propostas'"
                            :filtro="filtro"
                            :planos="propostasPlanos.data"
                          ></faturamentoChart>
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <vs-col
                              vs-type="flex"
                              vs-w="6"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <metaChart
                                :valores="[
                                  propostasPlanos.metas.qtdTotal,
                                  (propostasPlanos.metas.qtd_meta /
                                    filtro.diasUteis) *
                                    filtro.diasCorridos
                                ]"
                                :percent="
                                  parseFloat(
                                    (propostasPlanos.metas.qtdTotal * 100) /
                                      ((propostasPlanos.metas.qtd_meta /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos)
                                  ).toFixed(2)
                                "
                                height="5"
                                label="Total Propostas"
                              ></metaChart>
                            </vs-col>
                            <vs-col
                              vs-type="flex"
                              vs-w="6"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <metaChart
                                :valores="[
                                  $currency(
                                    parseFloat(propostasPlanos.metas.valorTotal)
                                  ),
                                  $currency(
                                    parseFloat(
                                      (propostasPlanos.metas.valor_meta /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos
                                    )
                                  )
                                ]"
                                :percent="
                                  parseFloat(
                                    (propostasPlanos.metas.valorTotal * 100) /
                                      ((propostasPlanos.metas.valor_meta /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos)
                                  ).toFixed(2)
                                "
                                height="5"
                                label="Valor"
                              ></metaChart>
                            </vs-col>
                          </vs-row>
                        </vs-row>
                      </div>
                    </vs-col>
                  </div>
                </vs-col>

                <vs-col vs-type="flex" vs-w="6">
                  <div class="mx-2 w-full row-card">
                    <vs-col
                      vs-type="flex"
                      vs-w="12"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <div class="w-full py-1">
                        <vs-row
                          vs-type="flex"
                          vs-w="12"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-col
                            vs-w="1"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            >&nbsp;</vs-col
                          >
                          <vs-col
                            vs-w="10"
                            class="pt-3"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <h5 class="font-semibold">DECLINIO</h5>
                          </vs-col>
                          <vs-col
                            vs-w="1"
                            vs-type="flex"
                            vs-justify="flex-end"
                            vs-align="flex-end"
                          >
                            <infoTooltip
                              color="dark"
                              titulo="DECLINIO"
                              :position="'left'"
                              :texto="tooltipTextos.declino"
                            ></infoTooltip>
                          </vs-col>
                        </vs-row>
                        <vs-row
                          vs-type="flex"
                          vs-w="12"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-col
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <donutChart
                              :propLabels="propostasDeclinio.labels"
                              :series="propostasDeclinio.series"
                              :colors="colors.chartDeclinio"
                              :valores="propostasDeclinio.valores"
                              :filtro="filtro"
                              :indices="propostasDeclinio.indices"
                              :tipo="2"
                              :percent="23"
                              label="proposta declinio"
                            ></donutChart>
                          </vs-col>
                        </vs-row>
                        <vs-row
                          vs-type="flex"
                          vs-w="12"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-col
                            vs-type="flex"
                            vs-w="6"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <metaChart
                              tipo="1"
                              :valores="[
                                'Total Declinios: ',
                                propostasDeclinio.total.qtd
                              ]"
                              :percent="100"
                              color="black"
                              height="5"
                              label="Ativas"
                            ></metaChart>
                          </vs-col>
                          <vs-col
                            vs-type="flex"
                            vs-w="6"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <metaChart
                              tipo="1"
                              height="5"
                              :valores="[
                                'Valor Perdido: ',
                                $currency(
                                  parseFloat(propostasDeclinio.total.valor)
                                )
                              ]"
                              :percent="100"
                              color="black"
                              label="Ativas"
                            ></metaChart>
                          </vs-col>
                        </vs-row>
                      </div>
                    </vs-col>
                  </div>
                </vs-col>
              </vs-row>
            </div>
            <div v-else>
              <vs-row
                vs-type="flex"
                vs-w="12"
                vs-justify="center"
                vs-align="center"
              >
                <vs-col
                  vs-type="flex"
                  vs-w="4"
                  vs-justify="center"
                  vs-align="center"
                >
                  <p class="mt-10 pt-10" style="font-size: 30px; height: 600px">
                    Carregando... {{ loading }}%
                  </p>
                </vs-col>
              </vs-row>
            </div>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>
<script>
import components from "@/components/default/exports.js";
import componentsVendas from "./components/charts/exports.js";
import VueApexCharts from "vue-apexcharts";
export default {
  props: {
    show: Boolean,
    filtro: Object
  },
  data() {
    return {
      loading: 0,
      tooltipTextos: {
        ativa: `PieChart representando as propostas, onde na sua prospecção foi
        selecionado o tipo de atendimento ligação ativa/abordagem ativa.
        Separado por propostas novas e propostas de renovação // XXX`,
        receptiva: `PieChart representando as propostas, onde na sua prospecção foi
        selecionado o tipo de atendimento ligação receptiva/abordagem receptiva.
        Separado por propostas novas e propostas de renovação // XXX`,
        planos: `Planos`,
        segmentos: `Segmentos`,
        declinio: `Segmentos`
      },
      colors: {
        chartAtivo: "#4caf50",
        chartReceptivo: "#ff6d00",
        chartSegmentos: "#0277bd",
        chartDeclinio: "#b71c1c"
      },
      propostasAtivas: {},
      propostasReceptivas: {},
      propostasSegmentos: {},
      propostasPlanos: { data: {}, metas: {} },
      propostasDeclinio: {},
      active: false,
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.8
      }
    };
  },
  methods: {
    async prepareDashboard() {
      await this.$vs.loading({
        container: "#container-propostas",
        type: "sound",
        scale: 0.6
      });
      await this.getPropostasAtivas();
      await this.getPropostasReceptivas();
      await this.getPropostasSegmentos();
      await this.getPropostasPlanos();
      await this.getPropostasDeclinio();
      this.active = true;
      await this.$vs.loading.close("#container-propostas > .con-vs-loading");
    },
    async getPropostasAtivas() {
      try {
        this.loading += 12;
        this.propostasAtivas = await this.$http.post(
          "getPropostasAtivas",
          this.filtro
        );
        this.loading += 13;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getPropostasReceptivas() {
      try {
        this.loading += 12;
        this.propostasReceptivas = await this.$http.post(
          "getPropostasReceptivas",
          this.filtro
        );

        this.loading += 13;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getPropostasSegmentos() {
      try {
        this.loading += 7;
        this.propostasSegmentos = await this.$http.post(
          "getPropostasSegmentos",
          this.filtro
        );

        this.loading += 9;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getPropostasPlanos() {
      try {
        this.loading += 7;
        this.propostasPlanos = await this.$http.post(
          "getPropostasPlanos",
          this.filtro
        );

        this.loading += 8;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getPropostasDeclinio() {
      try {
        this.propostasDeclinio = await this.$http.post(
          "getPropostasDeclinio",
          this.filtro
        );

        this.loading += 19;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    }
  },
  async mounted() {
    await this.prepareDashboard();
  },
  components: {
    ...components,
    ...componentsVendas,
    VueApexCharts
  }
};
</script>
<style scoped lang="scss">
.scroll-relatorio-vendas {
  max-height: 78vh !important;
}
.row-border {
  border-radius: 0px;
  border-bottom-style: solid;
  border-width: 1px;
}

.row-card {
  transition-duration: 250ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #f0f0f0e0;
    border-radius: 10px;
    color: black;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.prospeccaoVendas {
  max-height: 60vh !important;
}
.meta-card {
  transition-duration: 50ms;
  background-color: transparent;
  &:hover {
    transition-duration: 150ms;
    background-color: #0300be33;
    transform: translateY(-2px);
    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
