<template>
  <div class="w-full">
    <vue-apex-charts
      @dataPointSelection="dataPointSelectionHandler"
      :key="reRender"
      type="pie"
      width="400"
      :options="chartOptions"
      :series="series"
    ></vue-apex-charts>
    <component
      :label="label"
      :dataPie="selectedDataPie"
      :filtro="filtro"
      v-bind:is="modalPieChart"
      :key="reRenderModal"
    ></component>
  </div>
</template>
<script>
import clienteChartSideBar from "./../sideBars/clienteChartSideBar"
import prospeccaoChartSideBar from "./../sideBars/prospeccaoChartSideBar"
import tipoContatoChartSideBar from "./../sideBars/tipoContatoChartSideBar"
import components from "@/components/default/exports.js"
import VueApexCharts from "vue-apexcharts"
export default {
  props: {
    propLabels: null,
    series: null,
    colors: null,
    tipo: null,
    label: null,
    filtro: null,
    indices: null,
    textColor: null,
  },
  data () {
    return {
      selectedDataPie: {},
      modalPieChart: "",
      reRenderModal: 0,
      reRender: 0,
      chartOptions: {
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -10,
            },
          },
        },
        states: {
          active: {
            allowMultipleDataPointsSelection: true,
          },
        },
        chart: {
          events: {
            dataPointSelection: function (event, chartContext, config) { },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            },
            title: {
              formatter: function (seriesName) {
                return seriesName.split(": ")[0]
              },
            },
          },
        },
        theme: {
          mode: "light",
          palette: "palette3",
          monochrome: {
            enabled: true,
            color: this.colors,
            shadeTo: "dark",
            shadeIntensity: 0.3,
          },
        },

        labels: this.propLabels,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                fontSize: "24px",
                position: "bottom",
              },
            },
          },
        ],
      },
    }
  },
  methods: {
    dataPointSelectionHandler (e, chartContext, config) {
      this.selectedDataPie.label = this.propLabels[config.dataPointIndex]
      this.selectedDataPie.indice = this.indices[config.dataPointIndex]
      this.selectedDataPie.qtd = this.series[config.dataPointIndex]
      if (this.tipo == 1) {
        this.modalPieChart = "prospeccaoChartSideBar"
      } else {
        if (this.tipo == 2) {
          this.modalPieChart = "clienteChartSideBar"
        } else {
          this.modalPieChart = "tipoContatoChartSideBar"
        }
      }
      this.reRenderModal += 1
    },
  },
  components: {
    ...components,
    VueApexCharts,
    clienteChartSideBar,
    prospeccaoChartSideBar,
    tipoContatoChartSideBar,
  },
};
</script>
<style scoped lang="scss">
</style>
