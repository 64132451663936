<template>
  <v-card class="mx-auto" max-width="1000">
    <v-card-actions
      @click="show = !show"
      class="p-0"
      style="cursor: pointer; background-color: #ffca00"
    >
      <h4 class="px-3 mt-2">Clientes</h4>
      <span v-if="active" class="w-full">
        <vs-row
          vs-type="flex"
          vs-w="12"
          vs-justify="flex-end"
          vs-align="flex-end"
        >
          <vs-col
            vs-type="flex"
            vs-w="12"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <metaChart
              :tipo="3"
              :valores="[
                clientePie.clientes_novos.total,
                (clientePie.clientes_novos.meta / filtro.diasUteis) *
                  filtro.diasCorridos
              ]"
              :percent="
                parseFloat(
                  (clientePie.clientes_novos.total * 100) /
                    ((clientePie.clientes_novos.meta / filtro.diasUteis) *
                      filtro.diasCorridos)
                ).toFixed(2)
              "
            ></metaChart>
          </vs-col>
        </vs-row>
      </span>
    </v-card-actions>
    <VuePerfectScrollbar class="clientes-scroll" :settings="settings">
      <v-expand-transition>
        <div v-if="show">
          <v-card-text class="p-0">
            <div>
              <div
                id="container-cliente"
                class="w-full vs-con-loading__container"
              >
                <div v-if="active">
                  <vs-row
                    class="my-2"
                    vs-type="flex"
                    vs-w="12"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <vs-col
                      vs-type="flex"
                      vs-w="12"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <div class="w-full mx-2 row-card">
                        <vs-col
                          vs-w="12"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <div class="w-full">
                            <vs-row
                              vs-type="flex"
                              vs-w="12"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <vs-col
                                vs-w="1"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                                >&nbsp;</vs-col
                              >
                              <vs-col
                                vs-w="10"
                                class="pt-3"
                                vs-type="flex"
                                vs-justify="center"
                                vs-align="center"
                              >
                                <h5 class="font-semibold">
                                  CLIENTES NOVOS:
                                  {{ clientePie.clientes_novos.total }}
                                </h5>
                              </vs-col>
                              <vs-col
                                vs-w="1"
                                vs-type="flex"
                                vs-justify="flex-end"
                                vs-align="flex-end"
                              >
                                <infoTooltip
                                  color="dark"
                                  :position="'left'"
                                  titulo="CLIENTES"
                                  :texto="tooltipTextos.clientes"
                                ></infoTooltip>
                              </vs-col>
                            </vs-row>
                            <vs-row
                              vs-type="flex"
                              vs-w="12"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <pieChart
                                :propLabels="clientePie.clientes_novos.labels"
                                :series="clientePie.clientes_novos.series"
                                :indices="clientePie.clientes_novos.indices"
                                :filtro="filtro"
                                :percent="23"
                                :colors="colors[0]"
                                tipo="2"
                                label="clientes novos"
                              ></pieChart>
                            </vs-row>
                            <vs-row
                              vs-type="flex"
                              vs-w="12"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <vs-col
                                vs-type="flex"
                                vs-w="12"
                                vs-justify="center"
                                vs-align="center"
                              >
                                <metaChart
                                  :valores="[
                                    clientePie.clientes_novos.total,
                                    (clientePie.clientes_novos.meta /
                                      filtro.diasUteis) *
                                      filtro.diasCorridos
                                  ]"
                                  :percent="
                                    (
                                      (clientePie.clientes_novos.total * 100) /
                                      ((clientePie.clientes_novos.meta /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos)
                                    ).toFixed(2)
                                  "
                                  label="meta clientes"
                                ></metaChart>
                              </vs-col>
                            </vs-row>
                            <vs-row
                              vs-type="flex"
                              vs-w="12"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <vs-col
                                vs-w="1"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                                >&nbsp;</vs-col
                              >
                              <vs-col
                                vs-w="12"
                                class="pt-3"
                                vs-type="flex"
                                vs-justify="center"
                                vs-align="center"
                              >
                                <h5 class="font-semibold">
                                  CLIENTES ANTIGOS:
                                  {{ clientePie.clientes_antigos.total }}
                                </h5>
                              </vs-col>
                            </vs-row>
                            <vs-row
                              vs-type="flex"
                              vs-w="12"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <pieChart
                                :propLabels="clientePie.clientes_antigos.labels"
                                :series="clientePie.clientes_antigos.series"
                                :indices="clientePie.clientes_antigos.indices"
                                :filtro="filtro"
                                :percent="23"
                                tipo="2"
                                :colors="colors[1]"
                                label="clientes antigos"
                              ></pieChart>
                            </vs-row>
                          </div>
                        </vs-col>
                      </div>
                    </vs-col>
                  </vs-row>
                </div>
                <div v-else>
                  <vs-row
                    vs-type="flex"
                    vs-w="12"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <vs-col
                      vs-type="flex"
                      vs-w="12"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <p
                        class="mt-10 pt-10"
                        style="font-size: 30px; height: 480px"
                      >
                        Carregando... {{ loading }}%
                      </p>
                    </vs-col>
                  </vs-row>
                </div>
              </div>
            </div>
          </v-card-text>
        </div>
      </v-expand-transition>
    </VuePerfectScrollbar>
  </v-card>
</template>
<script>
import componentsVendas from "./components/charts/exports.js";
import components from "@/components/default/exports.js";
import VueApexCharts from "vue-apexcharts";
export default {
  props: {
    show: Boolean,
    filtro: Object
  },
  data() {
    return {
      clienteNaMao: {
        labels: ["Cliente Novo: ", "Cliente Antigo: 0"],
        series: [1, 1]
      },
      active: false,
      clientePie: null,
      tooltipTextos: { clientes: "Clientes" },
      colors: ["#0277bd", "#1b5e20"],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8
      },
      loading: 0
    };
  },
  methods: {
    async getRelatorioCliente() {
      try {
        this.clientePie = await this.$http.post(
          "getRelatorioCliente",
          this.filtro
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    }
  },
  async mounted() {
    await this.$vs.loading({
      container: "#container-cliente",
      type: "sound",
      scale: 0.6
    });
    this.loading += 35;
    await this.getRelatorioCliente();
    this.loading += 55;
    this.active = true;
    this.loading += 10;
    await this.$vs.loading.close("#container-cliente > .con-vs-loading");
  },
  components: {
    ...components,
    ...componentsVendas,
    VueApexCharts
  }
};
</script>
<style scoped lang="scss">
.scroll-relatorio-vendas {
  max-height: 78vh !important;
}
.row-border {
  border-radius: 0px;
  border-bottom-style: solid;
  border-width: 1px;
}

.row-card {
  transition-duration: 250ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #f0f0f0e0;
    border-radius: 10px;
    color: black;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.clientes-scroll {
  max-height: 60vh !important;
}
</style>
