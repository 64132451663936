var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"1000"}},[_c('v-card-actions',{staticClass:"p-0",staticStyle:{"cursor":"pointer","background-color":"#ffca00"},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('h4',{staticClass:"px-3 mt-2"},[_vm._v("Clientes")]),(_vm.active)?_c('span',{staticClass:"w-full"},[_c('vs-row',{attrs:{"vs-type":"flex","vs-w":"12","vs-justify":"flex-end","vs-align":"flex-end"}},[_c('vs-col',{attrs:{"vs-type":"flex","vs-w":"12","vs-justify":"flex-start","vs-align":"flex-start"}},[_c('metaChart',{attrs:{"tipo":3,"valores":[
              _vm.clientePie.clientes_novos.total,
              (_vm.clientePie.clientes_novos.meta / _vm.filtro.diasUteis) *
                _vm.filtro.diasCorridos
            ],"percent":parseFloat(
                (_vm.clientePie.clientes_novos.total * 100) /
                  ((_vm.clientePie.clientes_novos.meta / _vm.filtro.diasUteis) *
                    _vm.filtro.diasCorridos)
              ).toFixed(2)}})],1)],1)],1):_vm._e()]),_c('VuePerfectScrollbar',{staticClass:"clientes-scroll",attrs:{"settings":_vm.settings}},[_c('v-expand-transition',[(_vm.show)?_c('div',[_c('v-card-text',{staticClass:"p-0"},[_c('div',[_c('div',{staticClass:"w-full vs-con-loading__container",attrs:{"id":"container-cliente"}},[(_vm.active)?_c('div',[_c('vs-row',{staticClass:"my-2",attrs:{"vs-type":"flex","vs-w":"12","vs-justify":"center","vs-align":"center"}},[_c('vs-col',{attrs:{"vs-type":"flex","vs-w":"12","vs-justify":"center","vs-align":"center"}},[_c('div',{staticClass:"w-full mx-2 row-card"},[_c('vs-col',{attrs:{"vs-w":"12","vs-type":"flex","vs-justify":"center","vs-align":"center"}},[_c('div',{staticClass:"w-full"},[_c('vs-row',{attrs:{"vs-type":"flex","vs-w":"12","vs-justify":"center","vs-align":"center"}},[_c('vs-col',{attrs:{"vs-w":"1","vs-type":"flex","vs-justify":"flex-start","vs-align":"flex-start"}}),_c('vs-col',{staticClass:"pt-3",attrs:{"vs-w":"10","vs-type":"flex","vs-justify":"center","vs-align":"center"}},[_c('h5',{staticClass:"font-semibold"},[_vm._v(" CLIENTES NOVOS: "+_vm._s(_vm.clientePie.clientes_novos.total)+" ")])]),_c('vs-col',{attrs:{"vs-w":"1","vs-type":"flex","vs-justify":"flex-end","vs-align":"flex-end"}},[_c('infoTooltip',{attrs:{"color":"dark","position":'left',"titulo":"CLIENTES","texto":_vm.tooltipTextos.clientes}})],1)],1),_c('vs-row',{attrs:{"vs-type":"flex","vs-w":"12","vs-justify":"center","vs-align":"center"}},[_c('pieChart',{attrs:{"propLabels":_vm.clientePie.clientes_novos.labels,"series":_vm.clientePie.clientes_novos.series,"indices":_vm.clientePie.clientes_novos.indices,"filtro":_vm.filtro,"percent":23,"colors":_vm.colors[0],"tipo":"2","label":"clientes novos"}})],1),_c('vs-row',{attrs:{"vs-type":"flex","vs-w":"12","vs-justify":"center","vs-align":"center"}},[_c('vs-col',{attrs:{"vs-type":"flex","vs-w":"12","vs-justify":"center","vs-align":"center"}},[_c('metaChart',{attrs:{"valores":[
                                  _vm.clientePie.clientes_novos.total,
                                  (_vm.clientePie.clientes_novos.meta /
                                    _vm.filtro.diasUteis) *
                                    _vm.filtro.diasCorridos
                                ],"percent":(
                                    (_vm.clientePie.clientes_novos.total * 100) /
                                    ((_vm.clientePie.clientes_novos.meta /
                                      _vm.filtro.diasUteis) *
                                      _vm.filtro.diasCorridos)
                                  ).toFixed(2),"label":"meta clientes"}})],1)],1),_c('vs-row',{attrs:{"vs-type":"flex","vs-w":"12","vs-justify":"center","vs-align":"center"}},[_c('vs-col',{attrs:{"vs-w":"1","vs-type":"flex","vs-justify":"flex-start","vs-align":"flex-start"}}),_c('vs-col',{staticClass:"pt-3",attrs:{"vs-w":"12","vs-type":"flex","vs-justify":"center","vs-align":"center"}},[_c('h5',{staticClass:"font-semibold"},[_vm._v(" CLIENTES ANTIGOS: "+_vm._s(_vm.clientePie.clientes_antigos.total)+" ")])])],1),_c('vs-row',{attrs:{"vs-type":"flex","vs-w":"12","vs-justify":"center","vs-align":"center"}},[_c('pieChart',{attrs:{"propLabels":_vm.clientePie.clientes_antigos.labels,"series":_vm.clientePie.clientes_antigos.series,"indices":_vm.clientePie.clientes_antigos.indices,"filtro":_vm.filtro,"percent":23,"tipo":"2","colors":_vm.colors[1],"label":"clientes antigos"}})],1)],1)])],1)])],1)],1):_c('div',[_c('vs-row',{attrs:{"vs-type":"flex","vs-w":"12","vs-justify":"center","vs-align":"center"}},[_c('vs-col',{attrs:{"vs-type":"flex","vs-w":"12","vs-justify":"center","vs-align":"center"}},[_c('p',{staticClass:"mt-10 pt-10",staticStyle:{"font-size":"30px","height":"480px"}},[_vm._v(" Carregando... "+_vm._s(_vm.loading)+"% ")])])],1)],1)])])])],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }