<template>
  <v-card class="mx-auto" max-width="1000">
    <v-card-actions
      @click="show = !show"
      class="p-0"
      style="cursor: pointer; background-color: #ffca00"
    >
      <h4 class="px-3 mt-2">Atendimentos</h4>
      <span v-if="active" class="w-full">
        <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
          <vs-col
            vs-type="flex"
            vs-w="12"
            vs-justify="flex-end"
            vs-align="flex-end"
          >
            <metaChart
              :tipo="3"
              :valores="[prospeccaoPie.total, prospeccaoPie.meta]"
              :percent="
                parseFloat(
                  (prospeccaoPie.total * 100) /
                    ((prospeccaoPie.meta / filtro.diasUteis) *
                      filtro.diasCorridos)
                ).toFixed(2)
              "
            ></metaChart>
          </vs-col>
        </vs-row>
      </span>
    </v-card-actions>
    <VuePerfectScrollbar class="prospeccaoVendas" :settings="settings">
      <v-expand-transition>
        <div v-if="show">
          <v-card-text class="p-0">
            <div>
              <div
                id="container-prospeccao"
                class="w-full vs-con-loading__container"
              >
                <div v-if="active">
                  <vs-row
                    class="my-2"
                    vs-type="flex"
                    vs-w="12"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <vs-col
                      vs-type="flex"
                      vs-w="12"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <div class="w-full mx-2 row-card">
                        <vs-col
                          vs-w="12"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <div class="w-full">
                            <vs-row
                              vs-type="flex"
                              vs-w="12"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <vs-col
                                vs-w="10"
                                class="pt-3"
                                vs-type="flex"
                                vs-justify="center"
                                vs-align="center"
                              >
                                <h5 class="font-semibold">PROSPECÇÃO</h5>
                              </vs-col>
                              <vs-col
                                vs-w="1"
                                vs-type="flex"
                                vs-justify="flex-end"
                                vs-align="flex-end"
                              >
                                <infoTooltip
                                  color="dark"
                                  :position="'left'"
                                  titulo="PROSPECÇÃO"
                                  :texto="tooltipTextos.prospeccao"
                                ></infoTooltip>
                              </vs-col>
                            </vs-row>
                            <vs-row
                              vs-type="flex"
                              vs-w="12"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <pieChart
                                :propLabels="prospeccaoPie.labels"
                                :series="prospeccaoPie.series"
                                :percent="23"
                                :filtro="filtro"
                                :indices="prospeccaoPie.indices"
                                tipo="1"
                                :colors="colors"
                                label="PROSPECÇÕES"
                              ></pieChart>
                            </vs-row>
                          </div>
                        </vs-col>
                        <vs-col vs-w="12">
                          <metaChart
                            :valores="[prospeccaoPie.total, prospeccaoPie.meta]"
                            :percent="
                              parseFloat(
                                (prospeccaoPie.total * 100) /
                                  ((prospeccaoPie.meta / filtro.diasUteis) *
                                    filtro.diasCorridos)
                              ).toFixed(2)
                            "
                            label="Ativas"
                          ></metaChart>
                        </vs-col>
                      </div>
                    </vs-col>
                  </vs-row>
                  <vs-row
                    class="my-2"
                    vs-type="flex"
                    vs-w="12"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <vs-col
                      vs-type="flex"
                      vs-w="12"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <div class="w-full mx-2 my-3 row-card">
                        <vs-col
                          vs-w="12"
                          vs-type="flex"
                          class="mt-5"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <div class="w-full">
                            <vs-row
                              vs-type="flex"
                              vs-w="12"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <vs-col
                                vs-w="1"
                                vs-type="flex"
                                vs-justify="flex-end"
                                vs-align="flex-end"
                                >&nbsp;</vs-col
                              >
                              <vs-col
                                vs-w="10"
                                vs-type="flex"
                                vs-justify="center"
                                vs-align="center"
                              >
                                <h5 class="font-semibold">CONSULTORIA</h5>
                              </vs-col>
                              <vs-col
                                vs-w="1"
                                vs-type="flex"
                                vs-justify="flex-end"
                                vs-align="flex-end"
                              >
                                <infoTooltip
                                  color="dark"
                                  :position="'left'"
                                  titulo="CONSULTORIA"
                                  :texto="tooltipTextos.consultoria"
                                ></infoTooltip>
                              </vs-col>
                            </vs-row>
                            <vs-row
                              vs-type="flex"
                              vs-w="12"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <metaChart
                                :valores="[consultoria.total, consultoria.meta]"
                                :percent="
                                  parseFloat(
                                    (consultoria.total * 100) /
                                      ((consultoria.meta / filtro.diasUteis) *
                                        filtro.diasCorridos)
                                  ).toFixed(2)
                                "
                                label="Ativas"
                              ></metaChart>
                            </vs-row>
                          </div>
                        </vs-col>
                      </div>
                    </vs-col>
                  </vs-row>
                  <vs-row
                    class="my-2"
                    vs-type="flex"
                    vs-w="12"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <vs-col
                      vs-type="flex"
                      vs-w="12"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <div class="w-full mx-2 row-card">
                        <vs-col
                          vs-w="12"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <div class="w-full">
                            <vs-row
                              vs-type="flex"
                              vs-w="12"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <vs-col
                                vs-w="10"
                                class="pt-3"
                                vs-type="flex"
                                vs-justify="center"
                                vs-align="center"
                              >
                                <h5 class="font-semibold">ASSUNTOS GERAIS</h5>
                              </vs-col>
                              <vs-col
                                vs-w="1"
                                vs-type="flex"
                                vs-justify="flex-end"
                                vs-align="flex-end"
                              >
                                <infoTooltip
                                  color="dark"
                                  :position="'left'"
                                  titulo="ASSUNTOS GERAIS"
                                  :texto="tooltipTextos.assuntosGerais"
                                ></infoTooltip>
                              </vs-col>
                            </vs-row>
                            <vs-row
                              vs-type="flex"
                              vs-w="12"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <pieChart
                                :propLabels="assuntosGerais.labels"
                                :series="assuntosGerais.series"
                                :percent="23"
                                :filtro="filtro"
                                :indices="assuntosGerais.indices"
                                tipo="1"
                                :colors="colors"
                                label="ASSUNTOS GERAIS"
                              ></pieChart>
                            </vs-row>
                          </div>
                        </vs-col>
                      </div>
                    </vs-col>
                  </vs-row>
                </div>
                <div v-else>
                  <vs-row
                    vs-type="flex"
                    vs-w="12"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <vs-col
                      vs-type="flex"
                      vs-w="12"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <p
                        class="mt-10 pt-10"
                        style="font-size: 30px; height: 400px"
                      >
                        Carregando... {{ loading }}%
                      </p>
                    </vs-col>
                  </vs-row>
                </div>
              </div>
            </div>
          </v-card-text>
        </div>
      </v-expand-transition>
    </VuePerfectScrollbar>
  </v-card>
</template>
<script>
import componentsVendas from "./components/charts/exports.js";
import components from "@/components/default/exports.js";
import VueApexCharts from "vue-apexcharts";
export default {
  props: {
    show: Boolean,
    filtro: Object
  },
  data() {
    return {
      prospeccaoPie: {},
      assuntosGerais: {},
      prospeccaoData: {},
      active: false,
      consultoria: {},
      tooltipTextos: {
        prospeccao: "Prospeccao",
        consultoria: "Consultoria",
        assuntosGerais: "Todos os Assuntos"
      },
      series: null,
      colors: "#ffca00",
      valores: [40, 42],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8
      },
      loading: 0
    };
  },
  methods: {
    async getProspeccoesPie() {
      try {
        this.prospeccaoPie = await this.$http.post(
          "getProspeccaoPie",
          this.filtro
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getAssuntosGeraisAtendimento() {
      try {
        this.assuntosGerais = await this.$http.post(
          "getAssuntosGeraisAtendimento",
          this.filtro
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getProspeccaoConsultoria() {
      try {
        this.consultoria = await this.$http.post(
          "getProspeccaoConsultoria",
          this.filtro
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    }
  },
  async mounted() {
    await this.$vs.loading({
      container: "#container-prospeccao",
      type: "sound",
      scale: 0.6
    });
    this.loading += 20;
    await this.getProspeccoesPie();
    this.loading += 30;
    await this.getProspeccaoConsultoria();
    this.loading += 30;
    await this.getAssuntosGeraisAtendimento();
    this.active = true;
    this.loading += 20;
    await this.$vs.loading.close("#container-prospeccao > .con-vs-loading");
  },
  components: {
    ...components,
    ...componentsVendas,
    VueApexCharts
  }
};
</script>
<style scoped lang="scss">
.scroll-relatorio-vendas {
  max-height: 78vh !important;
}
.row-border {
  border-radius: 0px;
  border-bottom-style: solid;
  border-width: 1px;
}

.row-card {
  transition-duration: 250ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #f0f0f0e0;
    border-radius: 10px;
    color: black;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.prospeccaoVendas {
  max-height: 60vh !important;
}
</style>
