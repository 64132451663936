<template>
  <vue-apex-charts
    :key="reRender"
    type="radialBar"
    width="450"
    :options="commitRadialBar"
    :series="series"
  ></vue-apex-charts>
</template>
<script>
import components from "@/components/default/exports.js";
import VueApexCharts from "vue-apexcharts";
export default {
  props: {
    propLabels: null,
    series: null,
    color: null,
    label: null,
    textColor: null
  },
  data() {
    return {
      reRender: 0,
      radialBar: {
        labels: [],
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: "22px"
              },
              value: {
                fontSize: "16px"
              },
              total: {
                show: true,
                label: "Total"
              }
            }
          }
        }
      }
    };
  },
  computed: {
    commitRadialBar() {
      let localOptions = Object.assign({}, this.radialBar);
      localOptions.labels = this.propLabels;
      this.reRender += 1;

      return localOptions;
    }
  },
  components: {
    ...components,
    VueApexCharts
  }
};
</script>
<style scoped lang="scss">
</style>
