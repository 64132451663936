<template>
  <div>
    <vside
      @hide="fechar()"
      :active="modalPieChart"
      :item="{}"
      size="huge"
      textSucces="Finalizar"
    >
      <div slot="body">
        <div
          id="container-prospeccao-chart"
          class="mb-1 vs-con-loading__container"
        >
          <div
            class="mt-1"
            v-if="tableData.length == 0 || tableData.length == undefined"
          >
            <div class="pt-20 con-colors">
              <ul class="pt-20">
                <li class="danger-box">
                  <h2 class="p-5" style="color: white !important">
                    Nenhum Documento
                  </h2>
                </li>
              </ul>
            </div>
          </div>
          <div v-else>
            <div>
              <div>
                <vx-card card-background="dark">
                  <div class="p-2" style="color: white !important">
                    <span style="font-size: 20px">
                      <b style="text-transform: uppercase">{{ label }}</b>
                      - {{ dataPie.label.split(": ")[0] }}:&nbsp;
                    </span>
                    <span class="ml-2" style="font-size: 22px; color: #ffca00">
                      <b>{{ tableData.length }}</b>
                    </span>
                  </div>
                </vx-card>
              </div>
            </div>
            <!-- <VuePerfectScrollbar class="scroll-table-data" :settings="settings"> -->
            <vs-table
              maxHeight="75vh"
              pagination
              max-items="15"
              :data="tableData"
              stripe
            >
              <template slot="thead">
                <vs-th sort-key="nota" style="width: 1%" id="center"
                  >Contrato</vs-th
                >
                <vs-th sort-key="nota" style="width: 1%" id="center"
                  >N° Cliente</vs-th
                >
                <vs-th sort-key="emissao" style="width: 10%" id="center"
                  >Cliente</vs-th
                >
                <vs-th style="width: 10%" id="center">Assunto</vs-th>
                <vs-th style="width: 10%" id="center">Tipo Contato</vs-th>
                <vs-th style="width: 10%" id="center">Vendedor</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :key="row.contrato" v-for="row in data">
                  <vs-td class="text-center font-bold small-font">
                    <a
                      target="_blank"
                      :href="'/contrato?id=' + row.contrato"
                      style="color: darkblue; cursor: pointer"
                      >{{ row.contrato }}</a
                    >
                  </vs-td>
                  <vs-td class="text-center small-font">{{
                    row.id_cliente
                  }}</vs-td>
                  <vs-td class="text-center small-font">{{
                    row.cliente
                  }}</vs-td>
                  <vs-td class="text-center small-font">{{
                    row.assunto
                  }}</vs-td>
                  <vs-td class="text-center small-font">{{
                    row.tipo_contato
                  }}</vs-td>
                  <vs-td class="text-center small-font">{{
                    row.vendedor
                  }}</vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <!-- </VuePerfectScrollbar> -->
          </div>
        </div>
      </div>
    </vside>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
export default {
  props: {
    dataPie: null,
    label: null,
    filtro: null
  },
  data () {
    return {
      tableData: {},
      modalPieChart: false,
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 1,
        height: 1000
      }
    }
  },
  methods: {
    async fechar () {
      this.modalPieChart = false
    },
    async getData () {
      await this.$vs.loading({
        container: "#container-prospeccao-chart",
        type: "sound",
        scale: 0.6
      })
      try {
        this.tableData = await this.$http.post("getProspeccaoSideBar", {
          filtro: this.filtro,
          label: this.label,
          indice: this.dataPie.indice
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close(
          "#container-prospeccao-chart > .con-vs-loading"
        )
      }
    }
  },
  async mounted () {
    this.modalPieChart = true
    await this.getData()
  },
  components: {
    ...components
  }
};
</script>
<style lang="scss">
.scroll-table-data {
  height: 90vh !important;
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
</style>
