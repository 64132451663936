<template>
  <div class="w-full">
    <vs-row
      vs-type="flex"
      class="mt-0 mb-4"
      vs-justify="center"
      vs-align="center"
      vs-w="12"
    >
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="2"
      >
        <v-btn class="ma-2" @click="toggleCollapse" outlined color="indigo">
          <span style="font-size: 12px" class="m-0">Collapsar/Expandir</span>
        </v-btn>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="flex-end"
        vs-w="10"
      >
        <div class="w-full">
          <vs-row
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="flex-end"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="flex-end"
              vs-w="12"
              class="mb-2"
            >
              <vs-input
                color="dark"
                class="mx-1"
                type="date"
                size="small"
                label="Data De"
                v-model="filtro.dataDe"
              ></vs-input>
              <vs-input
                color="dark"
                class="mx-1"
                type="date"
                label="Data Até"
                size="small"
                v-model="filtro.dataAte"
              ></vs-input>
              <vs-input
                color="dark"
                class="mx-1"
                size="small"
                type="number"
                placeholder
                label="Dias Uteis"
                v-model="filtro.diasUteis"
              ></vs-input>
              <vs-input
                color="dark"
                class="mx-1"
                size="small"
                type="number"
                placeholder
                label="Dias Corridos"
                v-model="filtro.diasCorridos"
              ></vs-input>
            </vs-col>
          </vs-row>
          <vs-row
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="flex-end"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="flex-end"
              vs-w="12"
            >
              <el-select
                filterable
                size="small"
                clearable
                placeholder="Proviniente"
                v-model="filtro.proveniente"
                class="mx-1"
              >
                <el-option
                  v-for="proveniente in provenientes"
                  :value="proveniente.id"
                  :label="proveniente.nome"
                  :key="proveniente.id"
                ></el-option>
              </el-select>
              <el-select
                filterable
                size="small"
                clearable
                placeholder="Tipo Controle"
                v-model="filtro.controle"
                class="mx-1"
              >
                <el-option
                  v-for="controle in controles"
                  :value="controle.id"
                  :label="controle.nome"
                  :key="controle.id"
                ></el-option>
              </el-select>
              <el-select
                filterable
                size="small"
                clearable
                placeholder="Cidade"
                v-model="filtro.cidade"
                class="mx-1"
              >
                <el-option
                  v-for="cidade in cidades"
                  :value="cidade.id"
                  :label="cidade.nome"
                  :key="cidade.id"
                ></el-option>
              </el-select>
              <el-select
                filterable
                size="small"
                clearable
                multiple
                collapse-tags
                placeholder="Vendedor"
                v-model="filtro.vendedores"
                class="mx-1"
              >
                <el-option
                  v-for="vendedor in vendedores"
                  :value="vendedor.id"
                  :label="vendedor.nome"
                  :key="vendedor.id"
                ></el-option>
              </el-select>
              <el-select
                filterable
                size="small"
                clearable
                placeholder="Orçamentista"
                v-model="filtro.orcamentista"
                class="mx-1"
              >
                <el-option
                  v-for="orcamentista in orcamentistas"
                  :value="orcamentista.id"
                  :label="orcamentista.nome"
                  :key="orcamentista.id"
                ></el-option>
              </el-select>
              <el-button
                size="small"
                type="primary"
                class="mx-1"
                plain
                @click="filtrar()"
                >FILTRAR</el-button
              >
            </vs-col>
          </vs-row>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
export default {
  data() {
    return {
      faturamentos: Array,
      vendedores: [],
      orcamentistas: [],
      provenientes: [],
      controles: [],
      cidades: [],
      filtro: { dataDe: null, dataAte: null, vendedores: [], tipoContato: "3" },
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8
      }
    };
  },
  methods: {
    async toggleCollapse() {
      await this.$emit("toggleCollapse");
    },
    async filtrar() {
      if (this.filtro.dataDe != undefined && this.filtro.dataAte != undefined) {
        await this.$emit("atualizarFiltro", this.filtro);
      } else {
        this.$vs.notify({
          text: "Selecione um periodo para filtrar",
          color: "danger"
        });
      }
    },
    async mountData() {
      const date = new Date();
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
        .toISOString()
        .split("T")[0];
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        .toISOString()
        .split("T")[0];
      this.filtro.dataDe = firstDay;
      this.filtro.dataAte = lastDay;
    },
    async getVendedores() {
      try {
        this.vendedores = await this.$http.get(`colaborador`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getOrcamentistas() {
      const atividades = [11];
      try {
        this.orcamentistas = await this.$http.post(
          `colaboradorAtividade`,
          atividades
        );
        await this.$vs.loading.close();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getControles() {
      try {
        this.controles = await this.$http.get("controle");
        await this.$vs.loading.close();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getProvenientes() {
      try {
        this.provenientes = await this.$http.get(`proviniente`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getCidade() {
      try {
        this.cidades = await this.$http.get("getCidadesFiltro");
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    }
  },
  async mounted() {
    await this.getProvenientes();
    await this.getControles();
    await this.getOrcamentistas();
    await this.getCidade();
    await this.getVendedores();
    await this.mountData();
    await this.filtrar();
  },
  components: {
    ...components
  }
};
</script>
