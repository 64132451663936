<template>
  <v-card class="mx-auto" max-width="2000">
    <v-card-actions
      @click="show = !show"
      class="p-0"
      style="cursor: pointer; background-color: #ffca00"
    >
      <h4 class="px-3 mt-2">Funil de Vendas</h4>
    </v-card-actions>
    <v-expand-transition>
      <div v-if="show">
        <v-card-text class="p-0 mt-2">
          <div>
            <vs-row vs-type="flex" vs-align="center" vs-justify="center">
              <vs-col vs-type="flex" vs-align="center" vs-justify="center">
                <div
                  id="container-funil"
                  class="vs-con-loading__container w-full pb-3"
                  style="font-size: 18px"
                >
                  <vs-row
                    vs-type="flex"
                    vs-w="12"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                  >
                    <vs-col
                      vs-type="flex"
                      vs-w="3"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <div class="w-full">
                        <vs-row
                          vs-type="flex"
                          vs-w="12"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-col
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <h2>Ativos</h2>
                          </vs-col>
                        </vs-row>
                        <vs-row
                          vs-type="flex"
                          vs-w="12"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-col
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <div
                              class="mx-2 w-full"
                              @click="
                                openModal(`LeadsSidebar`, 1, {
                                  label: `Leads Ativos`
                                })
                              "
                            >
                              <div
                                class="w-full card-funil py-2 text-center"
                                style="border-color: #285e71"
                              >
                                <h6>Leads</h6>
                                <span style="font-size: 22px">{{
                                  ativos.leads.total
                                }}</span>
                              </div>
                            </div>
                          </vs-col>
                          <vs-col
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                            class="py-2 text-center"
                          >
                            <vs-icon
                              icon="arrow_downward"
                              size="40px"
                              color="#127486"
                              class="m-0 p-0"
                            ></vs-icon>
                            <span style="color: #127486" class="font-bold"
                              >{{
                                parseFloat(
                                  (ativos.prospeccoes.clientes /
                                    (ativos.leads.total > 0
                                      ? ativos.leads.total
                                      : 1)) *
                                    100
                                ).toFixed(2)
                              }}%</span
                            >
                          </vs-col>
                          <vs-col
                            vs-type="flex"
                            vs-w="11"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <div
                              class="mx-2 w-full"
                              @click="
                                openModal(`ProspeccoesSidebar`, 1, {
                                  label: `Prospecções Ativas`
                                })
                              "
                            >
                              <div
                                class="w-full card-funil py-2 text-center"
                                style="border-color: #127486"
                              >
                                <h6>Prospecções</h6>
                                <span style="font-size: 22px">
                                  {{ ativos.prospeccoes.clientes }}
                                </span>
                              </div>
                            </div>
                          </vs-col>
                          <vs-col
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                            class="py-2 text-center"
                          >
                            <vs-icon
                              icon="arrow_downward"
                              color="#008C96"
                              size="40px"
                              class="m-0 p-0"
                            ></vs-icon>

                            <span style="color: #008c96" class="font-bold"
                              >{{
                                parseFloat(
                                  (ativos.levantamentos.total /
                                    (ativos.prospeccoes.clientes > 0
                                      ? ativos.prospeccoes.clientes
                                      : 1)) *
                                    100
                                ).toFixed(2)
                              }}%</span
                            >
                          </vs-col>
                          <vs-col
                            vs-type="flex"
                            vs-w="10"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <div
                              class="mx-2 w-full"
                              @click="
                                openModal(`LevantamentosSidebar`, 1, {
                                  label: `Levantamentos Ativos`
                                })
                              "
                            >
                              <div
                                class="w-full card-funil py-2 text-center"
                                style="border-color: #008c96"
                              >
                                <h6>Levantamentos</h6>
                                <span style="font-size: 22px">{{
                                  ativos.levantamentos.total
                                }}</span>
                              </div>
                            </div>
                          </vs-col>
                          <vs-col
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                            class="py-2 text-center"
                          >
                            <vs-icon
                              icon="arrow_downward"
                              size="40px"
                              color="#00A49F"
                              class="m-0 p-0"
                            ></vs-icon>
                            <span style="color: #00a49f" class="font-bold"
                              >{{
                                parseFloat(
                                  (ativos.propostas.total /
                                    (ativos.prospeccoes.total > 0
                                      ? ativos.prospeccoes.total
                                      : 1)) *
                                    100
                                ).toFixed(2)
                              }}%</span
                            >
                          </vs-col>
                          <vs-col
                            vs-type="flex"
                            vs-w="9"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <div
                              class="mx-2 w-full"
                              @click="
                                openModal(`PropostasSidebar`, 1, {
                                  label: `Propostas Ativos`
                                })
                              "
                            >
                              <div
                                class="w-full card-funil py-2 text-center"
                                style="border-color: #00a49f"
                              >
                                <h6>Propostas</h6>
                                <span style="font-size: 22px">{{
                                  ativos.propostas.total
                                }}</span>
                                <span>&nbsp;{{ "&" }}&nbsp;</span>
                                <span>{{
                                  $currency(ativos.propostas.valor)
                                }}</span>
                              </div>
                            </div>
                          </vs-col>
                          <vs-col
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                            class="py-2 text-center"
                          >
                            <vs-icon
                              icon="arrow_downward"
                              size="40px"
                              color="success"
                              class="m-0 p-0"
                            ></vs-icon>
                            <span style="color: #00bba2" class="font-bold"
                              >{{
                                parseFloat(
                                  (ativos.contratos.valor /
                                    (ativos.propostas.valor > 0
                                      ? ativos.propostas.valor
                                      : 1)) *
                                    100
                                ).toFixed(2)
                              }}%</span
                            >
                          </vs-col>
                          <vs-col
                            vs-type="flex"
                            vs-w="8"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <div
                              class="mx-2 w-full"
                              @click="
                                openModal(`ContratosSidebar`, 1, {
                                  label: `Contratos Ativos`
                                })
                              "
                            >
                              <div
                                class="w-full card-funil py-2 text-center"
                                style="border-color: #00bba2"
                              >
                                <h6>Contratos</h6>
                                <span style="font-size: 22px">{{
                                  ativos.contratos.total
                                }}</span>
                                <span>&nbsp;{{ "&" }}&nbsp;</span>
                                <span>{{
                                  $currency(ativos.contratos.valor)
                                }}</span>
                              </div>
                            </div>
                          </vs-col>
                        </vs-row>
                      </div>
                    </vs-col>
                    <vs-col
                      vs-type="flex"
                      vs-w="0.5"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <h1>&nbsp;</h1>
                    </vs-col>
                    <vs-col
                      vs-type="flex"
                      vs-w="3"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <div class="w-full">
                        <vs-row
                          vs-type="flex"
                          vs-w="12"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-col
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <h2>Receptivos</h2>
                          </vs-col>
                        </vs-row>
                        <vs-row
                          vs-type="flex"
                          vs-w="12"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-col
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <div
                              class="mx-2 w-full"
                              @click="
                                openModal(`LeadsSidebar`, 2, {
                                  label: `Leads Receptivos`
                                })
                              "
                            >
                              <div
                                class="w-full card-funil py-2 text-center"
                                style="border-color: #285e71"
                              >
                                <h6>Leads</h6>
                                <span style="font-size: 22px">{{
                                  receptivos.leads.total
                                }}</span>
                              </div>
                            </div>
                          </vs-col>
                          <vs-col
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                            class="py-2 text-center"
                          >
                            <vs-icon
                              icon="arrow_downward"
                              size="40px"
                              color="#127486"
                              class="m-0 p-0"
                            ></vs-icon>
                            <span style="color: #127486" class="font-bold"
                              >{{
                                parseFloat(
                                  (receptivos.prospeccoes.clientes /
                                    (receptivos.leads.total > 0
                                      ? receptivos.leads.total
                                      : 1)) *
                                    100
                                ).toFixed(2)
                              }}%</span
                            >
                          </vs-col>
                          <vs-col
                            vs-type="flex"
                            vs-w="11"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <div
                              class="mx-2 w-full"
                              @click="
                                openModal(`ProspeccoesSidebar`, 2, {
                                  label: `Prospecções Receptivas`
                                })
                              "
                            >
                              <div
                                class="w-full card-funil py-2 text-center"
                                style="border-color: #127486"
                              >
                                <h6>Prospecções</h6>
                                <span style="font-size: 22px">
                                  {{ receptivos.prospeccoes.clientes }}
                                </span>
                              </div>
                            </div>
                          </vs-col>
                          <vs-col
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                            class="py-2 text-center"
                          >
                            <vs-icon
                              icon="arrow_downward"
                              color="#008C96"
                              size="40px"
                              class="m-0 p-0"
                            ></vs-icon>

                            <span style="color: #008c96" class="font-bold"
                              >{{
                                parseFloat(
                                  (receptivos.levantamentos.total /
                                    (receptivos.prospeccoes.clientes > 0
                                      ? receptivos.prospeccoes.clientes
                                      : 1)) *
                                    100
                                ).toFixed(2)
                              }}%</span
                            >
                          </vs-col>
                          <vs-col
                            vs-type="flex"
                            vs-w="10"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <div
                              class="mx-2 w-full"
                              @click="
                                openModal(`LevantamentosSidebar`, 2, {
                                  label: `Levantamentos Receptivas`
                                })
                              "
                            >
                              <div
                                class="w-full card-funil py-2 text-center"
                                style="border-color: #008c96"
                              >
                                <h6>Levantamentos</h6>
                                <span style="font-size: 22px">{{
                                  receptivos.levantamentos.total
                                }}</span>
                              </div>
                            </div>
                          </vs-col>
                          <vs-col
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                            class="py-2 text-center"
                          >
                            <vs-icon
                              icon="arrow_downward"
                              size="40px"
                              color="#00A49F"
                              class="m-0 p-0"
                            ></vs-icon>
                            <span style="color: #00a49f" class="font-bold"
                              >{{
                                parseFloat(
                                  (receptivos.propostas.total /
                                    (receptivos.prospeccoes.total > 0
                                      ? receptivos.prospeccoes.total
                                      : 1)) *
                                    100
                                ).toFixed(2)
                              }}%</span
                            >
                          </vs-col>
                          <vs-col
                            vs-type="flex"
                            vs-w="9"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <div
                              class="mx-2 w-full"
                              @click="
                                openModal(`PropostasSidebar`, 2, {
                                  label: `Propostas Receptivas`
                                })
                              "
                            >
                              <div
                                class="w-full card-funil py-2 text-center"
                                style="border-color: #00a49f"
                              >
                                <h6>Propostas</h6>
                                <span style="font-size: 22px">{{
                                  receptivos.propostas.total
                                }}</span>
                                <span>&nbsp;{{ "&" }}&nbsp;</span>
                                <span>{{
                                  $currency(receptivos.propostas.valor)
                                }}</span>
                              </div>
                            </div>
                          </vs-col>
                          <vs-col
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                            class="py-2 text-center"
                          >
                            <vs-icon
                              icon="arrow_downward"
                              size="40px"
                              color="success"
                              class="m-0 p-0"
                            ></vs-icon>
                            <span style="color: #00bba2" class="font-bold">
                              {{
                                parseFloat(
                                  (receptivos.contratos.valor /
                                    (receptivos.propostas.valor > 0
                                      ? receptivos.propostas.valor
                                      : 1)) *
                                    100
                                ).toFixed(2)
                              }}%
                            </span>
                          </vs-col>
                          <vs-col
                            vs-type="flex"
                            vs-w="8"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <div
                              class="mx-2 w-full"
                              @click="
                                openModal(`ContratosSidebar`, 2, {
                                  label: `Contratos Receptivas`
                                })
                              "
                            >
                              <div
                                class="w-full card-funil py-2 text-center"
                                style="border-color: #00bba2"
                              >
                                <h6>Contratos</h6>
                                <span style="font-size: 22px">{{
                                  receptivos.contratos.total
                                }}</span>
                                <span>&nbsp;{{ "&" }}&nbsp;</span>
                                <span>{{
                                  $currency(receptivos.contratos.valor)
                                }}</span>
                              </div>
                            </div>
                          </vs-col>
                        </vs-row>
                      </div>
                    </vs-col>
                    <vs-col
                      vs-type="flex"
                      vs-w="0.5"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <h1>&nbsp;</h1>
                    </vs-col>
                    <vs-col
                      vs-type="flex"
                      vs-w="3"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <div class="w-full">
                        <vs-row
                          vs-type="flex"
                          vs-w="12"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-col
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <h2>Renovação</h2>
                          </vs-col>
                        </vs-row>
                        <vs-row
                          vs-type="flex"
                          vs-w="12"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-col
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <div
                              class="mx-2 w-full"
                              @click="
                                openModal(`ContratosParaRenovarSidebar`, 0, {
                                  label: `Contratos Para Renovar`
                                })
                              "
                            >
                              <div
                                class="w-full card-funil py-2 text-center"
                                style="border-color: #00bba2"
                              >
                                <h6>Contratos Para Renovar</h6>
                                <span style="font-size: 22px">{{
                                  renovacoes.contratosParaRenovar.total
                                }}</span>
                                <span>&nbsp;{{ "&" }}&nbsp;</span>
                                <span>{{
                                  $currency(
                                    renovacoes.contratosParaRenovar.valor
                                  )
                                }}</span>
                              </div>
                            </div>
                          </vs-col>
                          <vs-col
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                            class="py-2 text-center"
                          >
                            <vs-icon
                              icon="arrow_downward"
                              size="40px"
                              color="#008C96"
                              class="m-0 p-0"
                            ></vs-icon>
                            <span style="color: #008c96" class="font-bold">
                              {{
                                parseFloat(
                                  (renovacoes.propostasRenovacao.valor /
                                    (renovacoes.contratosParaRenovar.valor > 0
                                      ? renovacoes.contratosParaRenovar.valor
                                      : 1)) *
                                    100
                                ).toFixed(2)
                              }}%
                            </span>
                          </vs-col>
                          <vs-col
                            vs-type="flex"
                            vs-w="11"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <div
                              class="mx-2 w-full"
                              @click="
                                openModal(`PropostasRenovacaoSidebar`, 0, {
                                  label: `Propostas Renovação`
                                })
                              "
                            >
                              <div
                                class="w-full card-funil py-2 text-center"
                                style="border-color: #008c96"
                              >
                                <h6>Proposta de Renovação</h6>
                                <span style="font-size: 22px">{{
                                  renovacoes.propostasRenovacao.total
                                }}</span>
                                <span>&nbsp;{{ "&" }}&nbsp;</span>
                                <span>{{
                                  $currency(renovacoes.propostasRenovacao.valor)
                                }}</span>
                              </div>
                            </div>
                          </vs-col>
                          <vs-col
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                            class="py-2 text-center"
                          >
                            <vs-icon
                              icon="arrow_downward"
                              size="40px"
                              color="success"
                              class="m-0 p-0"
                            ></vs-icon>
                            <span style="color: #00bba2" class="font-bold">
                              {{
                                parseFloat(
                                  (renovacoes.contratosRenovacao.valor /
                                    (renovacoes.propostasRenovacao.valor > 0
                                      ? renovacoes.propostasRenovacao.valor
                                      : 1)) *
                                    100
                                ).toFixed(2)
                              }}%
                            </span>
                          </vs-col>
                          <vs-col
                            vs-type="flex"
                            vs-w="10"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <div
                              class="mx-2 w-full"
                              @click="
                                openModal(`ContratosRenovacaoSidebar`, 0, {
                                  label: `Contratos Renovação`
                                })
                              "
                            >
                              <div
                                class="w-full card-funil py-2 text-center"
                                style="border-color: #00bba2"
                              >
                                <h6>Contrato de Renovação</h6>
                                <span style="font-size: 22px">{{
                                  renovacoes.contratosRenovacao.total
                                }}</span>
                                <span>&nbsp;{{ "&" }}&nbsp;</span>
                                <span>{{
                                  $currency(renovacoes.contratosRenovacao.valor)
                                }}</span>
                              </div>
                            </div>
                          </vs-col>
                        </vs-row>
                      </div>
                    </vs-col>
                    <vs-col
                      vs-type="flex"
                      vs-w="2"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <div class="w-full">
                        <vs-row
                          vs-type="flex"
                          vs-w="12"
                          vs-justify="center"
                          vs-align="center"
                          class="mb-5"
                        >
                          <vs-col
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <ul class="leftx">
                              <span class="text-center font-bold"
                                >Tipo de Contato</span
                              >
                              <li class="my-2 text-center">
                                <vs-radio
                                  v-model="filtro.tipoContato"
                                  vs-value="1"
                                  vs-name="radios1"
                                  color="success"
                                  @change="getFuncoes"
                                  >Ligação</vs-radio
                                >
                              </li>

                              <li class="my-2 text-center">
                                <vs-radio
                                  v-model="filtro.tipoContato"
                                  vs-value="2"
                                  @change="getFuncoes"
                                  vs-name="radios1"
                                  color="success"
                                  >Visita</vs-radio
                                >
                              </li>
                              <li class="my-2 text-center">
                                <vs-radio
                                  v-model="filtro.tipoContato"
                                  @change="getFuncoes"
                                  vs-value="3"
                                  vs-name="radios1"
                                  color="success"
                                  >Todos</vs-radio
                                >
                              </li>
                            </ul>
                          </vs-col>
                        </vs-row>
                        <vs-row
                          vs-type="flex"
                          vs-w="12"
                          vs-justify="center"
                          vs-align="center"
                          class="mb-5"
                        >
                          <vs-col
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <ul class="leftx text-center">
                              <span class="text-center font-bold"
                                >Segmento</span
                              >
                              <li
                                class="my-2 text-center"
                                v-for="(segmento, index) in segmentos"
                                :key="index"
                              >
                                <vs-radio
                                  v-model="filtro.id_segmento"
                                  :vs-value="segmento.id"
                                  vs-name="radios1"
                                  color="success"
                                  @change="getFuncoes"
                                  >{{ segmento.nome }}</vs-radio
                                >
                              </li>
                              <li class="my-2 text-center">
                                <vs-radio
                                  v-model="filtro.id_segmento"
                                  :vs-value="false"
                                  vs-name="radios1"
                                  color="success"
                                  @change="getFuncoes"
                                  >Todos</vs-radio
                                >
                              </li>
                            </ul>
                          </vs-col>
                        </vs-row>
                      </div>
                    </vs-col>
                  </vs-row>
                </div>
              </vs-col>
            </vs-row>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
    <component
      v-bind:is="bindComponent"
      :key="reRenderComponent"
      :item="itemSelecionado"
      :tipoContato="tipoContato"
      :tipo="tipo"
      :filtro="filtro"
    />
  </v-card>
</template>
<script>
import components from "@/components/default/exports.js";
import LeadsSidebar from "./sidebars/leadsSidebar";
import ProspeccoesSidebar from "./sidebars/prospeccoesSidebar";
import LevantamentosSidebar from "./sidebars/levantamentosSidebar";
import PropostasSidebar from "./sidebars/propostasSidebar";
import ContratosSidebar from "./sidebars/contratosSidebar";
import ContatosRenovacaoSidebar from "./sidebars/contatosRenovacaoSidebar";
import PropostasRenovacaoSidebar from "./sidebars/propostasRenovacaoSidebar";
import ContratosRenovacaoSidebar from "./sidebars/contratosRenovacaoSidebar";
import ContratosParaRenovarSidebar from "./sidebars/contratosParaRenovarSidebar";
export default {
  props: {
    show: Boolean,
    filtro: Object
  },
  components: {
    ...components,
    LeadsSidebar,
    ProspeccoesSidebar,
    LevantamentosSidebar,
    PropostasSidebar,
    ContratosSidebar,
    ContatosRenovacaoSidebar,
    PropostasRenovacaoSidebar,
    ContratosRenovacaoSidebar,
    ContratosParaRenovarSidebar
  },
  data() {
    return {
      ativos: {
        leads: { total: 0 },
        prospeccoes: { total: 0, clientes: 0 },
        levantamentos: { total: 0 },
        propostas: { total: 0, valor: 0 },
        contratos: { total: 0, valor: 0 }
      },
      receptivos: {
        leads: { total: 0 },
        prospeccoes: { total: 0, clientes: 0 },
        levantamentos: { total: 0 },
        propostas: { total: 0, valor: 0 },
        contratos: { total: 0, valor: 0 }
      },
      renovacoes: {
        contatosRenovacao: { total: 0 },
        propostasRenovacao: { total: 0 },
        contratosRenovacao: { total: 0 },
        contratosParaRenovar: { total: 0 }
      },
      tipoContato: "3",
      tipo: null,
      bindComponent: "",
      reRenderComponent: 0,
      itemSelecionado: {},
      segmentos: []
    };
  },
  methods: {
    async getFuncoes() {
      this.show
        ? await this.$vs.loading({
            container: "#container-funil",
            type: "sound",
            scale: 0.6
          })
        : 0;
      try {
        await this.resetarValores();
        await this.getLeadsFunil();
        await this.getProspeccoesFunil();
        await this.getLevantamentosFunil();
        await this.getPropostasFunil();
        await this.getContratosFunil();
        // await this.getContatosRenovacaoFunil();
        await this.getPropostasRenovacaoFunil();
        await this.getContratosRenovacaoFunil();
        await this.getContratosParaRenovarFunil();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.show
          ? await this.$vs.loading.close("#container-funil > .con-vs-loading")
          : 0;
      }
    },
    async resetarValores() {
      Object.assign(this.ativos, this._beforeEditingCacheAtivos);
      Object.assign(this.receptivos, this._beforeEditingCacheReceptivos);
      Object.assign(this.renovacoes, this._beforeEditingCacheRenovacoes);
    },
    async getLeadsFunil() {
      try {
        const res = await this.$http.post("getLeadsFunil", {
          filtro: this.filtro
        });
        res.ativos ? (this.ativos.leads = res.ativos) : 0;
        res.receptivos ? (this.receptivos.leads = res.receptivos) : 0;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getProspeccoesFunil() {
      try {
        const res = await this.$http.post("getProspeccoesFunil", {
          filtro: this.filtro
        });
        res.ativos
          ? ((this.ativos.prospeccoes = res.ativos),
            (this.ativos.prospeccoes.clientes = res.total.ativo))
          : 0;
        res.receptivos
          ? ((this.receptivos.prospeccoes = res.receptivos),
            (this.receptivos.prospeccoes.clientes = res.total.receptivo))
          : 0;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getLevantamentosFunil() {
      try {
        const res = await this.$http.post("getLevantamentosFunil", {
          filtro: this.filtro
        });
        res.ativos ? (this.ativos.levantamentos = res.ativos) : 0;
        res.receptivos ? (this.receptivos.levantamentos = res.receptivos) : 0;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getPropostasFunil() {
      try {
        const res = await this.$http.post("getPropostasFunil", {
          filtro: this.filtro
        });
        res.ativos ? (this.ativos.propostas = res.ativos) : 0;
        res.receptivos ? (this.receptivos.propostas = res.receptivos) : 0;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getContratosFunil() {
      try {
        const res = await this.$http.post("getContratosFunil", {
          filtro: this.filtro
        });
        res.ativos ? (this.ativos.contratos = res.ativos) : 0;
        res.receptivos ? (this.receptivos.contratos = res.receptivos) : 0;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getContatosRenovacaoFunil() {
      try {
        this.renovacoes.contatosRenovacao = await this.$http.post(
          "getContatosRenovacaoFunil",
          { filtro: this.filtro }
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getPropostasRenovacaoFunil() {
      try {
        this.renovacoes.propostasRenovacao = await this.$http.post(
          "getPropostasRenovacaoFunil",
          { filtro: this.filtro }
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getContratosRenovacaoFunil() {
      try {
        this.renovacoes.contratosRenovacao = await this.$http.post(
          "getContratosRenovacaoFunil",
          { filtro: this.filtro }
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getContratosParaRenovarFunil() {
      try {
        this.renovacoes.contratosParaRenovar = await this.$http.post(
          "getContratosParaRenovarFunil",
          { filtro: this.filtro }
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getSegmentos() {
      try {
        this.segmentos = await this.$http.get(`segmento`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async openModal(component, tipo, objData) {
      this.bindComponent = component;
      this.tipo = tipo;
      this.itemSelecionado.label = objData.label;
      objData.valor ? (this.itemSelecionado.valor = objData.valor) : 0;
      this.reRenderComponent++;
    }
  },
  async mounted() {
    this._beforeEditingCacheAtivos = Object.assign({}, this.ativos);
    this._beforeEditingCacheReceptivos = Object.assign({}, this.receptivos);
    this._beforeEditingCacheRenovacoes = Object.assign({}, this.renovacoes);
    await this.getSegmentos();
    await this.getFuncoes();
  }
};
</script>
<style lang="scss">
.card-funil {
  border-style: solid !important;
  border-width: 0px 5px 0px 5px !important;
  border-radius: 5px !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-4px);
    cursor: pointer;
  }
}
</style>
