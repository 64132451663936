<template>
  <vue-apex-charts
    type="radialBar"
    height="170"
    :options="chartOptions"
    :series="[percent ? percent : 0]"
  ></vue-apex-charts>
</template>
<script>
import components from "@/components/default/exports.js";
import VueApexCharts from "vue-apexcharts";
export default {
  props: { percent: null, color: null, label: null, textColor: null },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 350,
          type: "radialBar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: "70%",
              background: "#fffefe",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24,
              },
            },
            track: {
              background: "#fff",
              strokeWidth: "67%",
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35,
              },
            },
            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: "#000",
                fontSize: "12px",
              },
              value: {
                formatter: function (val) {
                  return parseInt(val);
                },
                color: "#000",
                fontSize: "18px",
                show: true,
              },
            },
          },
        },
        fill: {
          type: "",
          gradient: {
            shade: "dark",
            type: "",
            shadeIntensity: 1,
            gradientToColors: ["#000", "#000"],
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: [this.label],
      },
    };
  },
  methods: {},
  async mounted() {},
  components: {
    ...components,
    VueApexCharts,
  },
};
</script>
<style scoped lang="scss">
</style>
