<template>
  <v-card class="mx-auto" max-width="400">
    <v-card-actions
      @click="show = !show"
      class="p-0"
      style="cursor: pointer; background-color: #ffca00"
    >
      <h4 class="px-3 mt-2">Taxas de conversão</h4>
    </v-card-actions>
    <VuePerfectScrollbar class="scroll-taxas-comercial" :settings="settings">
      <v-expand-transition>
        <div v-if="show">
          <v-card-text class="p-0">
            <div>
              <div
                id="container-taxas-conversao"
                class="vs-con-loading__container"
              >
                <div v-if="active">
                  <vs-row
                    vs-type="flex"
                    vs-w="12"
                    vs-justify="flex-start"
                    vs-align="center"
                  >
                    <vs-col
                      class="my-2"
                      vs-type="flex"
                      vs-w="12"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <div class="mx-2 row-card w-full">
                        <vs-col
                          vs-w="12"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <h6 class="mt-2">
                            <span style="font-size: 14px">{{
                              taxas_comerciais.prospeccaoToProposta.total
                                .prospeccao
                            }}</span>
                            PROSPECÇÔES
                            <vs-icon icon="double_arrow"></vs-icon>&nbsp;
                            <span style="font-size: 14px">{{
                              taxas_comerciais.prospeccaoToProposta.total
                                .proposta
                            }}</span>
                            PROPOSTAS
                          </h6>
                        </vs-col>
                        <vs-col
                          vs-w="12"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <span class="mt-2">
                            <span style="font-size: 14px"></span> ATIVAS:
                            <span style="font-size: 14px">
                              {{
                                taxas_comerciais.prospeccaoToProposta.prospeccao
                                  .ativa
                              }}
                              <vs-icon icon="double_arrow"></vs-icon>
                              &nbsp;
                              {{
                                taxas_comerciais.prospeccaoToProposta.proposta
                                  .ativa
                              }}
                            </span>
                          </span>
                        </vs-col>
                        <vs-col
                          vs-w="12"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <span class="mt-2">
                            <span style="font-size: 14px"></span> RECEPTIVAS:
                            <span style="font-size: 14px">
                              {{
                                taxas_comerciais.prospeccaoToProposta.prospeccao
                                  .receptiva
                              }}
                              <vs-icon icon="double_arrow"></vs-icon>
                              &nbsp;
                              {{
                                taxas_comerciais.prospeccaoToProposta.proposta
                                  .receptiva
                              }}
                            </span>
                          </span>
                        </vs-col>

                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <div class="w-full px-2 py-1">
                            <circleChart
                              :percent="
                                (taxas_comerciais.prospeccaoToProposta.proposta
                                  .ativa /
                                  taxas_comerciais.prospeccaoToProposta
                                    .prospeccao.ativa) *
                                100
                              "
                              label="Ativas"
                            ></circleChart>
                          </div>
                        </vs-col>
                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <div class="w-full px-2 py-1">
                            <circleChart
                              :percent="
                                (taxas_comerciais.prospeccaoToProposta.proposta
                                  .receptiva /
                                  taxas_comerciais.prospeccaoToProposta
                                    .prospeccao.receptiva) *
                                100
                              "
                              label="Receptivas"
                            ></circleChart>
                          </div>
                        </vs-col>
                      </div>
                    </vs-col>
                    <vs-col class="mx-2 row-card" vs-w="12">
                      <div class="w-full">
                        <vs-col
                          vs-w="12"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <h6 class="mt-2">
                            <span style="font-size: 14px">{{
                              taxas_comerciais.propostaToContrato.total.proposta
                            }}</span>
                            PROPOSTAS
                            <vs-icon icon="double_arrow"></vs-icon>&nbsp;
                            <span style="font-size: 14px">{{
                              taxas_comerciais.propostaToContrato.total.contrato
                            }}</span>
                            CONTRATOS
                          </h6>
                        </vs-col>

                        <vs-col
                          vs-w="12"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <span class="mt-2">
                            <span style="font-size: 14px"></span> NOVOS:
                            <span style="font-size: 14px">
                              {{
                                taxas_comerciais.propostaToContrato.proposta
                                  .novo
                              }}
                              <vs-icon icon="double_arrow"></vs-icon>
                              &nbsp;
                              {{
                                taxas_comerciais.propostaToContrato.contrato
                                  .novo
                              }}
                            </span>
                          </span>
                        </vs-col>
                        <vs-col
                          vs-w="12"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <span class="mt-2">
                            <span style="font-size: 14px"></span> RENOVAÇÃO:
                            <span style="font-size: 14px">
                              {{
                                taxas_comerciais.propostaToContrato.proposta
                                  .renovacao
                              }}
                              <vs-icon icon="double_arrow"></vs-icon>
                              &nbsp;
                              {{
                                taxas_comerciais.propostaToContrato.contrato
                                  .renovacao
                              }}
                            </span>
                          </span>
                        </vs-col>

                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <div class="w-full px-2 py-1">
                            <circleChart
                              :percent="
                                (taxas_comerciais.propostaToContrato.contrato
                                  .novo /
                                  taxas_comerciais.propostaToContrato.proposta
                                    .novo) *
                                100
                              "
                              label="Novas"
                            ></circleChart>
                          </div>
                        </vs-col>
                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <div class="w-full px-2 py-1">
                            <circleChart
                              :percent="
                                (taxas_comerciais.propostaToContrato.contrato
                                  .renovacao /
                                  taxas_comerciais.propostaToContrato.proposta
                                    .renovacao) *
                                100
                              "
                              label="Renovações"
                            ></circleChart>
                          </div>
                        </vs-col>
                      </div>
                    </vs-col>
                    <vs-col
                      vs-w="12"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      class="mx-2 row-card"
                    >
                      <div class="w-full">
                        <vs-col
                          vs-w="12"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <h6 class="mt-2">
                            <span style="font-size: 14px">
                              {{
                                taxas_comerciais.contratosFaturados.total
                              }}&nbsp;CONTRATOS
                              <vs-icon icon="double_arrow"></vs-icon>
                              &nbsp;
                              {{
                                taxas_comerciais.contratosFaturados.faturado
                              }}&nbsp;FATURADOS
                            </span>
                          </h6>
                        </vs-col>
                        <vs-col
                          vs-w="12"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <div class="w-full px-2 py-1">
                            <circleChart
                              :percent="
                                (taxas_comerciais.contratosFaturados.faturado /
                                  taxas_comerciais.contratosFaturados.total) *
                                100
                              "
                              label="Faturados"
                            ></circleChart>
                          </div>
                        </vs-col>
                      </div>
                    </vs-col>
                  </vs-row>
                </div>
                <div v-else>
                  <vs-row
                    vs-type="flex"
                    vs-w="12"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <vs-col
                      vs-type="flex"
                      vs-w="12"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <p
                        class="mt-10 pt-10"
                        style="font-size: 30px; height: 450px"
                      >
                        Carregando... {{ loading }}%
                      </p>
                    </vs-col>
                  </vs-row>
                </div>
              </div>
            </div>
          </v-card-text>
        </div>
      </v-expand-transition>
    </VuePerfectScrollbar>
  </v-card>
</template>
<script>
import componentsVendas from "./components/charts/exports.js"

import components from "@/components/default/exports.js"
import VueApexCharts from "vue-apexcharts"
export default {
  props: {
    show: Boolean,
    filtro: Object,
  },
  data () {
    return {
      taxas: [],
      taxas_comerciais: {},
      active: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8,
      },
      loading: 0
    }
  },
  methods: {
    async getTaxasComerciais () {
      try {
        this.taxas_comerciais = await this.$http.post(
          `getTaxasComerciais`,
          this.filtro
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
  },
  async mounted () {
    await this.$vs.loading({
      container: "#container-taxas-conversao",
      type: "sound",
      scale: 0.6,
    })
    this.loading += 35
    await this.getTaxasComerciais()
    this.loading += 65
    this.active = true
    this.loading += 10
    await this.$vs.loading.close("#container-taxas-conversao > .con-vs-loading")
  },
  components: {
    ...components,
    ...componentsVendas,
    VueApexCharts,
  },
};
</script>
<style scoped lang="scss">
.scroll-taxas-comercial {
  max-height: 60vh !important;
}
.row-border {
  border-radius: 0px;
  border-bottom-style: solid;
  border-width: 1px;
}

.row-card {
  transition-duration: 250ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #f0f0f0e0;
    border-radius: 10px;
    color: black;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
