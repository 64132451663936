<template>
  <div>
    <vs-row type="flex" vs-justify="space-around">
      <vs-col
        vs-w="3.4"
        class="px-2"
        v-for="(parcela, index) in parcelas"
        :key="index"
      >
        <vx-card
          @click="showmodalParcelasChart(parcela)"
          style="cursor: pointer"
          :class="'plan-card overflow-hidden my-5'"
        >
          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <h6 style>{{ parcela.parcela }}</h6>
          </vs-col>
          <vs-divider
            border-style="dotted"
            color
            class="py-0 mb-0 my-0"
          ></vs-divider>
          <div>
            <div>
              <vs-row class="mt-4">
                <vs-col vs-type vs-justify="center" vs-align="center" vs-w="12">
                  <h5 class="text-center" style="color: black">
                    {{ parcela.qtd }} parcelas
                  </h5>
                </vs-col>

                <vs-col vs-type vs-justify="center" vs-align="center" vs-w="12">
                  <h4 class="text-center" style="color: #00bba2">
                    {{ $currency(parcela.valor) }}
                  </h4>
                </vs-col>
              </vs-row>
            </div>
          </div>
        </vx-card>
      </vs-col>
    </vs-row>
    <component
      :open="true"
      :label="label"
      :parcela="selectedParcela"
      :filtro="filtro"
      v-bind:is="modalParcelasChart"
      :key="reRenderModal"
    ></component>
  </div>
</template>

<script>
import parcelasChartSideBar from "./../sideBars/parcelasChartSideBar"
export default {
  props: {
    parcelas: null,
    label: null,
    filtro: null,
  },
  data () {
    return {
      modalParcelasChart: null,
      reRenderModal: 1,
      selectedParcela: null,
    }
  },
  methods: {
    async showmodalParcelasChart (parcela) {
      this.selectedParcela = parcela
      this.reRenderModal = this.reRenderModal + 1
      this.modalParcelasChart = "parcelasChartSideBar"
    },
  },
  components: {
    parcelasChartSideBar,
  },
};
</script>
<style lang="scss" scoped>
.plan-card {
  transition-duration: 50ms;
  background-color: #fbfbfb;
  border-style: solid;
  border-radius: 7px;
  border-color: #000a12;
  border-width: 2px;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-5px);
    border-color: #00bba2;
    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
