<template>
  <v-card class="mx-auto" max-width="2000">
    <v-card-actions
      @click="show = !show"
      class="p-0"
      style="cursor: pointer; background-color: #ffca00"
    >
      <h4 class="px-3 mt-2">Faturamentos</h4>
      <span v-if="active" class="w-full">
        <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center">
          <vs-col
            vs-type="flex"
            vs-w="3"
            vs-justify="flex-start"
            vs-align="flex-start"
          ></vs-col>
          <vs-col
            vs-type="flex"
            vs-w="4.5"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <metaChart
              :tipo="3"
              :valores="[
                relatorioFaturamento.planos.metas.qtd_total,
                (relatorioFaturamento.planos.metas.qtd_meta /
                  filtro.diasUteis) *
                  filtro.diasCorridos
              ]"
              :percent="
                parseFloat(
                  (relatorioFaturamento.planos.metas.qtd_total /
                    ((relatorioFaturamento.planos.metas.qtd_meta /
                      filtro.diasUteis) *
                      filtro.diasCorridos)) *
                    100
                ).toFixed(2)
              "
            ></metaChart>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-w="4.5"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <metaChart
              :tipo="3"
              :valores="[
                $currency(
                  parseFloat(relatorioFaturamento.planos.metas.valor_total)
                ),
                $currency(
                  parseFloat(
                    (relatorioFaturamento.planos.metas.valor_meta /
                      filtro.diasUteis) *
                      filtro.diasCorridos
                  )
                )
              ]"
              :percent="
                parseFloat(
                  (relatorioFaturamento.planos.metas.valor_total /
                    ((relatorioFaturamento.planos.metas.valor_meta /
                      filtro.diasUteis) *
                      filtro.diasCorridos)) *
                    100
                ).toFixed(2)
              "
            ></metaChart>
          </vs-col>
        </vs-row>
      </span>
    </v-card-actions>
    <v-expand-transition>
      <div v-if="show">
        <v-card-text class="p-0 mt-2">
          <div>
            <div id="container-faturamento" class="vs-con-loading__container">
              <div v-if="active">
                <vs-row
                  vs-type="flex"
                  vs-w="12"
                  vs-justify="flex-start"
                  vs-align="center"
                >
                  <vs-col vs-type="flex" vs-w="6">
                    <div class="mx-3 w-full row-card">
                      <vs-col
                        vs-type="flex"
                        vs-w="12"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <div class="w-full py-1">
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                              >&nbsp;</vs-col
                            >
                            <vs-col
                              vs-w="10"
                              class="pt-3"
                              vs-type="flex"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <h5 class="font-semibold">PLANOS</h5>
                            </vs-col>
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-end"
                              vs-align="flex-end"
                            >
                              <infoTooltip
                                color="dark"
                                titulo="PLANOS"
                                :position="'left'"
                                :texto="tooltipTextos.planos"
                              ></infoTooltip>
                            </vs-col>
                          </vs-row>
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <faturamentoChart
                              :planos="relatorioFaturamento.planos.data"
                              :filtro="filtro"
                              :label="' faturamentos'"
                            ></faturamentoChart>
                            <vs-row
                              vs-type="flex"
                              vs-w="12"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <vs-col
                                vs-type="flex"
                                vs-w="6"
                                vs-justify="center"
                                vs-align="center"
                              >
                                <metaChart
                                  :valores="[
                                    relatorioFaturamento.planos.metas.qtd_total,
                                    (relatorioFaturamento.planos.metas
                                      .qtd_meta /
                                      filtro.diasUteis) *
                                      filtro.diasCorridos
                                  ]"
                                  :percent="
                                    parseFloat(
                                      (relatorioFaturamento.planos.metas
                                        .qtd_total /
                                        ((relatorioFaturamento.planos.metas
                                          .qtd_meta /
                                          filtro.diasUteis) *
                                          filtro.diasCorridos)) *
                                        100
                                    ).toFixed(2)
                                  "
                                  label="Total Contratos"
                                ></metaChart>
                              </vs-col>
                              <vs-col
                                vs-type="flex"
                                vs-w="6"
                                vs-justify="center"
                                vs-align="center"
                              >
                                <metaChart
                                  :valores="[
                                    $currency(
                                      parseFloat(
                                        relatorioFaturamento.planos.metas
                                          .valor_total
                                      )
                                    ),
                                    $currency(
                                      parseFloat(
                                        (relatorioFaturamento.planos.metas
                                          .valor_meta /
                                          filtro.diasUteis) *
                                          filtro.diasCorridos
                                      )
                                    )
                                  ]"
                                  :percent="
                                    parseFloat(
                                      (relatorioFaturamento.planos.metas
                                        .valor_total /
                                        ((relatorioFaturamento.planos.metas
                                          .valor_meta /
                                          filtro.diasUteis) *
                                          filtro.diasCorridos)) *
                                        100
                                    ).toFixed(2)
                                  "
                                  label="Valor"
                                ></metaChart>
                              </vs-col>
                            </vs-row>
                          </vs-row>
                        </div>
                      </vs-col>
                    </div>
                  </vs-col>
                  <vs-col vs-type="flex" vs-w="6">
                    <div class="m-3 w-full row-card">
                      <vs-col
                        vs-type="flex"
                        vs-w="12"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <div class="w-full py-1">
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                              >&nbsp;</vs-col
                            >
                            <vs-col
                              vs-w="10"
                              class="pt-3"
                              vs-type="flex"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <h5 class="font-semibold">PARCELAS</h5>
                            </vs-col>
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-end"
                              vs-align="flex-end"
                            >
                              <infoTooltip
                                color="dark"
                                :position="'left'"
                                titulo="PARCELAS"
                                :texto="tooltipTextos.parcelas"
                              ></infoTooltip>
                            </vs-col>
                          </vs-row>
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <parcelasChart
                              :filtro="filtro"
                              :label="' faturamentos'"
                              :parcelas="relatorioFaturamento.parcelas"
                            ></parcelasChart>
                          </vs-row>
                        </div>
                      </vs-col>
                    </div>
                  </vs-col>
                  <vs-col vs-type="flex" vs-w="6">
                    <div class="m-3 w-full row-card">
                      <vs-col
                        vs-type="flex"
                        vs-w="12"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <div class="w-full py-1">
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                              >&nbsp;</vs-col
                            >
                            <vs-col
                              vs-w="10"
                              class="pt-3"
                              vs-type="flex"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <h5 class="font-semibold">Para Faturar</h5>
                            </vs-col>
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-end"
                              vs-align="flex-end"
                            >
                              <infoTooltip
                                color="dark"
                                :position="'left'"
                                titulo="PARCELAS"
                                :texto="tooltipTextos.parcelas"
                              ></infoTooltip>
                            </vs-col>
                          </vs-row>
                          <vs-col
                            class="mt-10"
                            vs-w="12"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <h3>Mensal</h3>
                          </vs-col>
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <vs-col vs-w="6">
                              <metaChart
                                :valores="[
                                  parseFloat(
                                    relatorioFaturamento.paraFaturar.data[0].qtd
                                  ),
                                  parseFloat(
                                    (relatorioFaturamento.paraFaturar.data[0]
                                      .meta_qtd /
                                      filtro.diasUteis) *
                                      filtro.diasCorridos
                                  )
                                ]"
                                :percent="
                                  parseFloat(
                                    (relatorioFaturamento.paraFaturar.data[0]
                                      .qtd /
                                      ((relatorioFaturamento.paraFaturar.data[0]
                                        .meta_qtd /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos)) *
                                      100
                                  ).toFixed(2)
                                "
                                label="Quantidade"
                              ></metaChart>
                            </vs-col>
                            <vs-col vs-w="6">
                              <metaChart
                                :valores="[
                                  $currency(
                                    parseFloat(
                                      relatorioFaturamento.paraFaturar.data[0]
                                        .valor
                                    )
                                  ),
                                  $currency(
                                    parseFloat(
                                      (relatorioFaturamento.paraFaturar.data[0]
                                        .meta_valor /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos
                                    )
                                  )
                                ]"
                                :percent="
                                  parseFloat(
                                    (relatorioFaturamento.paraFaturar.data[0]
                                      .valor /
                                      ((relatorioFaturamento.paraFaturar.data[0]
                                        .meta_valor /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos)) *
                                      100
                                  ).toFixed(2)
                                "
                                label="Valor"
                              ></metaChart>
                            </vs-col>
                          </vs-row>
                          <vs-col
                            class="mt-16"
                            vs-w="12"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <h3>Global</h3>
                          </vs-col>
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <vs-col vs-w="6">
                              <metaChart
                                :valores="[
                                  parseFloat(
                                    relatorioFaturamento.paraFaturar.data[1].qtd
                                  ),
                                  parseFloat(
                                    (relatorioFaturamento.paraFaturar.data[1]
                                      .meta_qtd /
                                      filtro.diasUteis) *
                                      filtro.diasCorridos
                                  )
                                ]"
                                :percent="
                                  parseFloat(
                                    (relatorioFaturamento.paraFaturar.data[1]
                                      .qtd /
                                      ((relatorioFaturamento.paraFaturar.data[1]
                                        .meta_qtd /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos)) *
                                      100
                                  ).toFixed(2)
                                "
                                label="Quantidade"
                              ></metaChart>
                            </vs-col>
                            <vs-col vs-w="6">
                              <metaChart
                                :valores="[
                                  $currency(
                                    parseFloat(
                                      relatorioFaturamento.paraFaturar.data[1]
                                        .valor
                                    )
                                  ),
                                  $currency(
                                    parseFloat(
                                      (relatorioFaturamento.paraFaturar.data[1]
                                        .meta_valor /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos
                                    )
                                  )
                                ]"
                                :percent="
                                  parseFloat(
                                    (relatorioFaturamento.paraFaturar.data[1]
                                      .valor /
                                      ((relatorioFaturamento.paraFaturar.data[1]
                                        .meta_valor /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos)) *
                                      100
                                  ).toFixed(2)
                                "
                                label="Valor"
                              ></metaChart>
                            </vs-col>
                          </vs-row>
                          <vs-col
                            class="mt-16"
                            vs-w="12"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <h3>Total</h3>
                          </vs-col>
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <vs-col vs-w="6">
                              <metaChart
                                :valores="[
                                  parseFloat(
                                    relatorioFaturamento.paraFaturar.data[1]
                                      .qtd +
                                      relatorioFaturamento.paraFaturar.data[0]
                                        .qtd
                                  ),
                                  parseFloat(
                                    (relatorioFaturamento.paraFaturar.data[1]
                                      .meta_qtd /
                                      filtro.diasUteis) *
                                      filtro.diasCorridos +
                                      (relatorioFaturamento.paraFaturar.data[0]
                                        .meta_qtd /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos
                                  )
                                ]"
                                :percent="
                                  parseFloat(
                                    ((relatorioFaturamento.paraFaturar.data[1]
                                      .qtd +
                                      relatorioFaturamento.paraFaturar.data[0]
                                        .qtd) /
                                      ((relatorioFaturamento.paraFaturar.data[1]
                                        .meta_qtd /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos +
                                        (relatorioFaturamento.paraFaturar
                                          .data[0].meta_qtd /
                                          filtro.diasUteis) *
                                          filtro.diasCorridos)) *
                                      100
                                  ).toFixed(2)
                                "
                                label="Quantidade"
                              ></metaChart>
                            </vs-col>
                            <vs-col vs-w="6">
                              <metaChart
                                :valores="[
                                  $currency(
                                    parseFloat(
                                      relatorioFaturamento.paraFaturar.data[1]
                                        .valor +
                                        relatorioFaturamento.paraFaturar.data[0]
                                          .valor
                                    )
                                  ),
                                  $currency(
                                    parseFloat(
                                      (relatorioFaturamento.paraFaturar.data[1]
                                        .meta_valor /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos +
                                        (relatorioFaturamento.paraFaturar
                                          .data[0].meta_valor /
                                          filtro.diasUteis) *
                                          filtro.diasCorridos
                                    )
                                  )
                                ]"
                                :percent="
                                  parseFloat(
                                    ((relatorioFaturamento.paraFaturar.data[1]
                                      .valor +
                                      relatorioFaturamento.paraFaturar.data[0]
                                        .valor) /
                                      ((relatorioFaturamento.paraFaturar.data[1]
                                        .meta_valor /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos +
                                        (relatorioFaturamento.paraFaturar
                                          .data[0].meta_valor /
                                          filtro.diasUteis) *
                                          filtro.diasCorridos)) *
                                      100
                                  ).toFixed(2)
                                "
                                label="Valor"
                              ></metaChart>
                            </vs-col>
                          </vs-row>
                        </div>
                      </vs-col>
                    </div>
                  </vs-col>
                  <vs-col vs-type="flex" vs-w="6">
                    <div class="m-3 w-full row-card">
                      <vs-col
                        vs-type="flex"
                        vs-w="12"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <div class="w-full py-1">
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                              >&nbsp;</vs-col
                            >
                            <vs-col
                              vs-w="10"
                              class="pt-3"
                              vs-type="flex"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <h5 class="font-semibold">Faturado</h5>
                            </vs-col>
                            <vs-col
                              vs-w="1"
                              vs-type="flex"
                              vs-justify="flex-end"
                              vs-align="flex-end"
                            >
                              <infoTooltip
                                color="dark"
                                :position="'left'"
                                titulo="PARCELAS"
                                :texto="tooltipTextos.parcelas"
                              ></infoTooltip>
                            </vs-col>
                          </vs-row>
                          <vs-col
                            class="mt-10"
                            vs-w="12"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <h3>Mensal</h3>
                          </vs-col>
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <vs-col vs-w="6">
                              <metaChart
                                :valores="[
                                  parseFloat(
                                    relatorioFaturamento.faturados.data[0].qtd
                                  ),
                                  parseFloat(
                                    (relatorioFaturamento.faturados.data[0]
                                      .meta_qtd /
                                      filtro.diasUteis) *
                                      filtro.diasCorridos
                                  )
                                ]"
                                :percent="
                                  parseFloat(
                                    (relatorioFaturamento.faturados.data[0]
                                      .qtd /
                                      ((relatorioFaturamento.faturados.data[0]
                                        .meta_qtd /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos)) *
                                      100
                                  ).toFixed(2)
                                "
                                label="Quantidade"
                              ></metaChart>
                            </vs-col>
                            <vs-col vs-w="6">
                              <metaChart
                                :valores="[
                                  $currency(
                                    parseFloat(
                                      relatorioFaturamento.faturados.data[0]
                                        .valor
                                    )
                                  ),
                                  $currency(
                                    parseFloat(
                                      (relatorioFaturamento.faturados.data[0]
                                        .meta_valor /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos
                                    )
                                  )
                                ]"
                                :percent="
                                  parseFloat(
                                    (relatorioFaturamento.faturados.data[0]
                                      .valor /
                                      ((relatorioFaturamento.faturados.data[0]
                                        .meta_valor /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos)) *
                                      100
                                  ).toFixed(2)
                                "
                                label="Valor"
                              ></metaChart>
                            </vs-col>
                          </vs-row>
                          <vs-col
                            class="mt-16"
                            vs-w="12"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <h3>Global</h3>
                          </vs-col>
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <vs-col vs-w="6">
                              <metaChart
                                :valores="[
                                  parseFloat(
                                    relatorioFaturamento.faturados.data[1].qtd
                                  ),
                                  parseFloat(
                                    (relatorioFaturamento.faturados.data[1]
                                      .meta_qtd /
                                      filtro.diasUteis) *
                                      filtro.diasCorridos
                                  )
                                ]"
                                :percent="
                                  parseFloat(
                                    (relatorioFaturamento.faturados.data[1]
                                      .qtd /
                                      ((relatorioFaturamento.faturados.data[1]
                                        .meta_qtd /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos)) *
                                      100
                                  ).toFixed(2)
                                "
                                label="Quantidade"
                              ></metaChart>
                            </vs-col>
                            <vs-col vs-w="6">
                              <metaChart
                                :valores="[
                                  $currency(
                                    parseFloat(
                                      relatorioFaturamento.faturados.data[1]
                                        .valor
                                    )
                                  ),
                                  $currency(
                                    parseFloat(
                                      (relatorioFaturamento.faturados.data[1]
                                        .meta_valor /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos
                                    )
                                  )
                                ]"
                                :percent="
                                  parseFloat(
                                    (relatorioFaturamento.faturados.data[1]
                                      .valor /
                                      ((relatorioFaturamento.faturados.data[1]
                                        .meta_valor /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos)) *
                                      100
                                  ).toFixed(2)
                                "
                                label="Valor"
                              ></metaChart>
                            </vs-col>
                          </vs-row>
                          <vs-col
                            class="mt-16"
                            vs-w="12"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <h3>Total</h3>
                          </vs-col>
                          <vs-row
                            vs-type="flex"
                            vs-w="12"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <vs-col vs-w="6">
                              <metaChart
                                :valores="[
                                  parseFloat(
                                    relatorioFaturamento.faturados.data[1].qtd +
                                      relatorioFaturamento.faturados.data[0].qtd
                                  ),
                                  parseFloat(
                                    (relatorioFaturamento.faturados.data[1]
                                      .meta_qtd /
                                      filtro.diasUteis) *
                                      filtro.diasCorridos +
                                      (relatorioFaturamento.faturados.data[0]
                                        .meta_qtd /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos
                                  )
                                ]"
                                :percent="
                                  parseFloat(
                                    ((relatorioFaturamento.faturados.data[1]
                                      .qtd +
                                      relatorioFaturamento.faturados.data[0]
                                        .qtd) /
                                      ((relatorioFaturamento.faturados.data[1]
                                        .meta_qtd /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos +
                                        (relatorioFaturamento.faturados.data[1]
                                          .meta_qtd /
                                          filtro.diasUteis) *
                                          filtro.diasCorridos)) *
                                      100
                                  ).toFixed(2)
                                "
                                label="Quantidade"
                              ></metaChart>
                            </vs-col>
                            <vs-col vs-w="6">
                              <metaChart
                                :valores="[
                                  $currency(
                                    parseFloat(
                                      relatorioFaturamento.faturados.data[1]
                                        .valor +
                                        relatorioFaturamento.faturados.data[0]
                                          .valor
                                    )
                                  ),
                                  $currency(
                                    parseFloat(
                                      (relatorioFaturamento.faturados.data[1]
                                        .meta_valor /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos +
                                        (relatorioFaturamento.faturados.data[0]
                                          .meta_valor /
                                          filtro.diasUteis) *
                                          filtro.diasCorridos
                                    )
                                  )
                                ]"
                                :percent="
                                  parseFloat(
                                    (relatorioFaturamento.faturados.data[1]
                                      .valor +
                                      relatorioFaturamento.faturados.data[0]
                                        .valor) /
                                      ((relatorioFaturamento.faturados.data[1]
                                        .meta_valor /
                                        filtro.diasUteis) *
                                        filtro.diasCorridos +
                                        (relatorioFaturamento.faturados.data[1]
                                          .meta_valor /
                                          filtro.diasUteis) *
                                          filtro.diasCorridos *
                                          100)
                                  ).toFixed(2)
                                "
                                label="Valor"
                              ></metaChart>
                            </vs-col>
                          </vs-row>
                        </div>
                      </vs-col>
                    </div>
                  </vs-col>
                </vs-row>
              </div>
              <div v-else>
                <vs-row
                  vs-type="flex"
                  vs-w="12"
                  vs-justify="center"
                  vs-align="center"
                >
                  <vs-col
                    vs-type="flex"
                    vs-w="12"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <p
                      class="mt-10 pt-10"
                      style="font-size: 30px; height: 250px"
                    >
                      Carregando... {{ loading }}%
                    </p>
                  </vs-col>
                </vs-row>
              </div>
            </div>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>
<script>
import componentsVendas from "./components/charts/exports.js";
import components from "@/components/default/exports.js";
import VueApexCharts from "vue-apexcharts";
export default {
  props: {
    show: Boolean,
    filtro: Object
  },
  data() {
    return {
      active: false,
      relatorioFaturamento: {},
      tooltipTextos: {
        parcelas: `Parcelas`,
        planos: "Planos",
        metas: "Metas"
      },
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.8
      },
      loading: 0
    };
  },
  methods: {
    async getRelatorioFaturamento() {
      try {
        this.relatorioFaturamento = await this.$http.post(
          `getRelatorioFaturamento`,
          this.filtro
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.active = await true;
      }
    }
  },
  async mounted() {
    await this.$vs.loading({
      container: "#container-faturamento",
      type: "sound",
      scale: 0.6
    });
    this.loading += 45;
    await this.getRelatorioFaturamento();
    this.loading += 55;
    await this.$vs.loading.close("#container-faturamento > .con-vs-loading");
  },
  components: {
    ...components,
    ...componentsVendas,
    VueApexCharts
  }
};
</script>
<style lang="scss">
.scroll-relatorio-vendas {
  max-height: 78vh !important;
}
.row-border {
  border-radius: 0px;
  border-bottom-style: solid;
  border-width: 1px;
}

.row-card {
  transition-duration: 250ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #f0f0f0e0;
    border-radius: 10px;
    color: black;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.prospeccaoVendas {
  max-height: 60vh !important;
}
</style>
